import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook, faXTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import styles from './SocialIcons.module.css';

const SocialIcons = ({ links }) => {
    const iconMapping = {
        linkedin: faLinkedin,
        facebook: faFacebook,
        twitter: faXTwitter, // Adjust this if the icon name changes
        instagram: faInstagram,
    };

    return (
        <div className={styles.socialIcons}>
            {links.map(({ platform, url }) => (
                <a key={platform} href={url} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={iconMapping[platform]} />
                </a>
            ))}
        </div>
    );
};

export default SocialIcons;
