import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import InputField from '../components/InputField';
import Button from '../components/Button';
import MaxWidth from '../components/MaxWidth';
import styles from './LoginForm.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Modal from "../components/Modal";
import _fetch from "../utils/_fetch";
import Verify2FACode from './Verify2FACode';
import image from "../images/5562385_21404.webp";
import FullHeightContainer from '../components/FullHeightContainer';

const LoginForm = ({ user, onError, onSuccess, onLogin }) => {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const navigate = useNavigate();
    const [isOTLModalOpen, setIsOTLModalOpen] = useState(false);
    const [is2FAEnabled, setIs2FAEnabled] = useState(false);
    const [otp, setOTP] = useState(null);

    // Hook to access the current location (URL)
    const location = useLocation();

    useEffect(() => {

        const fastLogin = async () => {
            // Check if OTP and email are in the link (query params)
            const queryParams = new URLSearchParams(location.search); // Extract query string from the URL
            const otp = queryParams.get('otp'); // Get the value of "otp" param
            const email = queryParams.get('email'); // Get the value of "email" param

            if (otp && email) {
                setEmail(email);
                try {
                    const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/auth/otp/validate`, 'POST', {
                        email,
                        otp
                    });
                    if (responseData.is2FAEnabled) {
                        setIs2FAEnabled(true);
                        setOTP(responseData.otp);
                    } else {
                        await handleLogin();
                    }
                } catch (error) {
                    onError(error.message);
                }
            }
        }

        fastLogin();

    }, [location.search]); // Dependency on location.search to re-run when URL changes

    const handleOTL = async (event) => {
        event.preventDefault();
        try {
            const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/auth/otp/request`, 'POST', {
                email
            });
            onSuccess(responseData.message);
        } catch (error) {
            onError(error.message);
        } finally {
            setIsOTLModalOpen(false);
        }
    };

    const handleLogin = async () => {
        const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/auth/user/me`, 'GET');
        onLogin(responseData);
        navigate('/home');
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/auth/login`, 'POST', {
                email,
                password
            });
            console.log(responseData);
            if (responseData.is2FAEnabled) {
                setIs2FAEnabled(true);
                setOTP(responseData.otp);
            } else {
                await handleLogin();
            }
        } catch (error) {
            onError(error.message);
        }
    };

    const handleCodeVerified = async () => {
        await handleLogin();
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.imageCover}>
                    <img src={image} alt="Cover" className={styles.coverImage} />
                </div>
                <div className={styles.formContainer}>
                    <MaxWidth maxWidth={450}>
                        <h2>Login into your Account</h2>
                        <form onSubmit={handleSubmit} className={styles.form}>
                            <InputField
                                id="loginEmail"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                label="Email"
                                required
                            />
                            <InputField
                                id="loginPassword"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                label="Password"
                                required
                            />
                            <Button type="submit" className={styles.submitButton}>Login</Button>
                            <p className={styles.toggleLink}>
                                Forgot your password?{' '}
                                <Link to="/reset-password" className={styles.resetPasswordLink}>Reset Password</Link>
                            </p>
                        </form>
                        <Button className={styles.otlButton} onClick={() => { setIsOTLModalOpen(true); }}>
                            <FontAwesomeIcon icon={faEnvelope} /> Sign in with a One-Time Link
                        </Button>

                        <h3>No account?</h3>
                        <p>Are you a company looking for interns?</p>
                        <Link to="/signup">
                            <Button className={styles.signup}>Register as a Company</Button>
                        </Link>

                        <p>Are you a student looking for an internship?</p>
                        <Link to="/signup">
                            <Button className={styles.signup}>Register as a Student</Button>
                        </Link>
                    </MaxWidth>
                </div>
            </div>

            <Modal
                isOpen={isOTLModalOpen}
                onClose={() => { setIsOTLModalOpen(false); }}
            >
                <h2>Sign in with OTL</h2>
                <p>Enter your email to receive a OTL.</p>
                <form onSubmit={handleOTL}>
                    <InputField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required={true}
                    ></InputField>
                    <Button type='submit'>Send</Button>
                </form>
            </Modal>


            <Modal
                isOpen={is2FAEnabled}
                onClose={() => { setIs2FAEnabled(false); }}
            >
                <Verify2FACode
                    onError={onError}
                    onSuccess={onSuccess}
                    onCodeVerified={async () => {
                        setIs2FAEnabled(false);
                        await handleCodeVerified();
                    }}
                    on2FAReset={() => {
                        setIs2FAEnabled(false);
                    }}
                    email={email}
                    otp={otp}
                ></Verify2FACode>
            </Modal>
        </>

    );
};

export default LoginForm;
