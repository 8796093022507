import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import Link
import InputField from '../components/InputField';
import Button from '../components/Button';
import Card from '../components/Card';
import styles from './ResetPasswordForm.module.css'; // Import CSS Module
import MaxWidth from '../components/MaxWidth';

const ResetPasswordForm = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('http://localhost/api/auth/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (response.ok && data.status === 'success') {
                setMessage('Password reset email sent. Check your inbox.');
                setTimeout(() => navigate('/login'), 3000); // Redirect after 3 seconds
            } else {
                setError(data.message || 'Failed to send password reset email.');
            }
        } catch (err) {
            setError('Failed to send password reset email.');
        }
    };

    return (
        <MaxWidth maxWidth="450px">
            <h2>Reset Password</h2>
            <form onSubmit={handleSubmit} className={styles.form}>
                <InputField
                    id="resetEmail"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email"
                    required
                />
                <Button type="submit">Send Reset Link</Button>
                <p className={styles.toggleLink}>
                    Remembered your password?{' '}
                    <Link to="/login">Login</Link>
                </p>
                {message && <div className={styles.successMessage}>{message}</div>}
                {error && <div className={styles.errorMessage}>{error}</div>}
            </form>
        </MaxWidth>
    );
};

export default ResetPasswordForm;
