import React from 'react';
import styles from './Button.module.css'; // Import CSS Module

const Button = ({ type = 'button', onClick, children, className, style, title }) => {
    return (
        <button 
            type={type}
            onClick={onClick}
            className={`${styles.button} ${className}`}
            style={style}
            title={title}
        >
            {children}
        </button>
    );
};

export default Button;
