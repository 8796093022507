import React from 'react';
import styles from './Modal.module.css'; // Import CSS Module

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null; // Do not render modal if not open

    return (
        <div className={styles.overlay} onClick={onClose}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

export default Modal;
