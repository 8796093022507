// RelativeContainer.jsx
import React from 'react';
import styles from './RelativeContainer.module.css'; // Adjust the path as needed

const RelativeContainer = ({ children }) => {
    return (
        <div className={styles.container}>
            {children}
        </div>
    );
};

export default RelativeContainer;
