import React, { Component, useEffect, useState } from 'react';
import { faEquals, faFilter, faGreaterThanEqual, faInfoCircle, faLessThanEqual, faPlus, faQuestionCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import FullLayout from '../components/FullLayout'; // Import FullLayout component
import styles from './Internships.module.css'; // Import CSS Module for internships styles
import SlidingContainer from '../components/SlidingContainer';
import AboutCompany from '../components/AboutCompany';
import DynamicComponentRenderer from '../components/DynamicComponentRenderer';
import PaddingContainer from '../components/PaddingContainer';
import SkillCheckForm from "../components/SkillCheckForm";
import ActionButtonsContainer from '../components/ActionButtonsContainer';
import _fetch from '../utils/_fetch';
import CenteredMessage from '../components/CenteredMessage';

const Internships = ({ onError, onSuccess, onUserGuideClick }) => {

    const [company, setCompany] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [isAddNewIntershipSCOpen, setIsAddNewIntershipSCOpen] = useState(false);
    const [isAboutCompanySCOpen, setIsAboutCompanySCOpen] = useState(false);
    const [selectedInternship, setSelectedInternship] = useState(null);
    const [internships, setInternships] = useState([]);

    useEffect(() => {
        setCompanyName('cross-ING AG');

        const fetchInternships = async () => {
            try {
                const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/internship`, 'GET');
                setInternships(responseData.internships);
            } catch (error) {
                onError(error.message);
            }
        }

        fetchInternships();

    }, []);

    const buttonData = [
        { icon: faPlus, label: 'Add New Internship', onClick: () => { setIsAddNewIntershipSCOpen(true); } },
        { icon: faInfoCircle, label: <div>About <div>{companyName}</div></div>, onClick: () => { setIsAboutCompanySCOpen(true); } },
        { icon: faQuestionCircle, label: 'User Guide', onClick: () => alert('User Guide Clicked') } // Added User Guide button
    ];


    const searchPerfectMatch = (se) => {

    };

    const searchPerfectMatchButtons = [
        { icon: faSearch, label: 'Search Best Fit', onClick: () => { searchPerfectMatch(1); } },
        // { icon: faGreaterThanEqual, label: 'Hard Search', onClick: () => { searchPerfectMatch(2); } },
        // { icon: faLessThanEqual, label: 'Soft Search', onClick: () => { searchPerfectMatch(3); } },
        { icon: faFilter, label: 'Filters', onClick: () => { } },
        { icon: faQuestionCircle, label: 'User Guide', onClick: onUserGuideClick }
    ];

    return (
        <>

            <FullLayout buttons={buttonData}>
                <div className={styles.internship}>
                    <div className={styles.internshipList}>
                        {!internships.length ? <CenteredMessage message={"No internship found."}></CenteredMessage> :
                            <>
                                {internships.map(internship => {
                                    return (
                                        <>
                                            {internship.name}
                                        </>
                                    )
                                })}
                            </>}
                    </div>
                    <div className={styles.main}>
                        {selectedInternship && <PaddingContainer>
                            <DynamicComponentRenderer
                                components={[
                                    { label: "Description" },
                                    {
                                        label: "Search", component:
                                            <>
                                                <SkillCheckForm
                                                    onError={onError}
                                                    onSuccess={onSuccess}
                                                    isInEditingMode={false}
                                                    moreButtons={searchPerfectMatchButtons}
                                                ></SkillCheckForm>
                                            </>

                                    },
                                    { label: "Students" }
                                ]}
                            ></DynamicComponentRenderer>
                        </PaddingContainer>}
                    </div>
                </div>
            </FullLayout>

            <SlidingContainer
                isOpen={isAddNewIntershipSCOpen}
                onClose={() => { setIsAddNewIntershipSCOpen(false); }}
            >
            </SlidingContainer>

            <SlidingContainer
                isOpen={isAboutCompanySCOpen}
                onClose={() => { setIsAboutCompanySCOpen(false); }}
            >
                <AboutCompany></AboutCompany>

            </SlidingContainer>

        </>
    );
};

export default Internships;
