import React from 'react';
import styles from './MaxWidth.module.css'; // Import CSS Module

const MaxWidth = ({ children, maxWidth = '880px', className }) => {
    return (
        <div className={`${styles.maxWidth} ${className}`} style={{ maxWidth }}>
            {children}
        </div>
    );
};

export default MaxWidth;
