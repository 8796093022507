import React, { useState } from 'react';
import InputField from './InputField';  // Assuming InputField is in the same directory
import Button from './Button';  // Assuming Button is in the same directory

const Form = ({ fields, submitButtonLabel = 'Submit', onSubmit }) => {
    const [formData, setFormData] = useState(
        fields.reduce((acc, field) => {
            acc[field.key] = '';  // Initialize each field with an empty string
            return acc;
        }, {})
    );

    const handleChange = (key, value) => {
        setFormData(prevData => ({
            ...prevData,
            [key]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit}>
            {fields.map((field, index) => (
                <InputField
                    key={index}
                    id={field.key}
                    type={field.type || 'text'}  // Default to 'text' if type is not specified
                    value={formData[field.key]}
                    onChange={(e) => handleChange(field.key, e.target.value)}
                    label={field.name}
                    required={field.isRequired}
                    disabled={field.isDisabled}
                    icons={field.icons}  // Pass icons if any
                />
            ))}
            <Button type="submit" style={{ marginTop: '20px' }}>{submitButtonLabel}</Button>
        </form>
    );
};

export default Form;
