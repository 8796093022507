import React, { useEffect, useState } from 'react';
import styles from './NavigationBar.module.css';

const NavigationBar = ({ items }) => {
    const [selectedItem, setSelectedItem] = useState(0);

    const handleItemClick = (index) => {
        setSelectedItem(index);
        items[index].callback();
    };

    useEffect(() => {
        // Cleanup: reset selected item on unmount
        return () => {
            setSelectedItem(0);
        };
    }, []);

    return (
        <nav className={styles.nav}>
            <ul>
                {items.map((item, index) => (
                    <li key={index}>
                        <h3
                            className={`${styles.navigationBarButton} ${selectedItem === index ? styles.selectedNavigationBarButton : ''}`}
                            onClick={() => handleItemClick(index)}
                        >
                            {item.label}
                        </h3>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default NavigationBar;
