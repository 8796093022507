// WorkInProgress.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonDigging, faSpinner } from '@fortawesome/free-solid-svg-icons';
import styles from './WorkInProgress.module.css'; // Import the CSS module

const WorkInProgress = () => {
    return (
        <div className={styles.container}>
            <FontAwesomeIcon icon={faPersonDigging} className={styles.icon} />
            <h1>Work in Progress</h1>
            <p>We are currently working on this page. Please check back later.</p>
        </div>
    );
};

export default WorkInProgress;
