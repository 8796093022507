import { faQuestionCircle, faSave, faUser, faClose, faEnvelope, faCog } from "@fortawesome/free-solid-svg-icons";
import FullLayout from "../components/FullLayout";
import MaxWidth from "../components/MaxWidth";
import SkillCheckForm from "../components/SkillCheckForm";
import SlidingContainer from "../components/SlidingContainer";
import Spinner from "../components/Spinner";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import _fetch from "../utils/_fetch";

const SkillCheck = ({ onError, onSuccess, onUserGuideClick }) => {
    const { token } = useParams();  // Get the token from the URL

    const [isLoading, setIsLoading] = useState(true);
    const [isMeSCOpen, setIsMeSCOpen] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const verifyToken = async () => {
            try {
                const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/skill-update-request/verify/${token}`, 'GET');
                setUser(responseData);
            } catch (error) {
                onError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        verifyToken();
    }, [token, onError]);

    const handleSubmitSkills = () => {
        // Logic for handling the skill submission
    };

    // Build the buttons array
    const buttons = [
        { icon: faSave, label: "Save", onClick: handleSubmitSkills },
        { icon: faUser, label: "Me", onClick: () => setIsMeSCOpen(true) }
    ];

    // Conditionally add buttons if user is a student
    if (user?.isStudent) {
        buttons.push(
            { icon: faEnvelope, label: "My Contact Requests", onClick: () => { /* Contact requests logic */ } },
            { icon: faCog, label: "Preferences", onClick: () => { /* Preferences logic */ } },
            { icon: faClose, label: "Delete Profile", onClick: () => { /* Delete profile logic */ } }
        );
    }

    buttons.push({ icon: faQuestionCircle, label: "User Guide", onClick: onUserGuideClick });

    return (
        <>
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    <FullLayout buttons={buttons}>
                        <MaxWidth>
                            <SkillCheckForm
                                onError={onError}
                                onSuccess={onSuccess}
                                isInEditingMode={false}
                            />
                        </MaxWidth>
                    </FullLayout>

                    <SlidingContainer
                        isOpen={isMeSCOpen}
                        onClose={() => setIsMeSCOpen(false)}
                    >
                        {/* Content for SlidingContainer */}
                    </SlidingContainer>
                </>
            )}
        </>
    );
};

export default SkillCheck;
