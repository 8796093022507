// ListCounter.js
import React from 'react';
import styles from './ListCounter.module.css';

const ListCounter = ({ listLength }) => {
    return (
        <div className={styles.counter}>
            {listLength}
        </div>
    );
};

export default ListCounter;
