import React from 'react';
import MaxWidth from '../components/MaxWidth';
import PaddingContainer from '../components/PaddingContainer';

const TermsOfService = () => {
    const companyName = process.env.REACT_APP_COMPANY_NAME;
    const email = process.env.REACT_APP_COMPANY_EMAIL;
    return (
        <MaxWidth>
            <PaddingContainer>
                <h1>Terms of Service</h1>
                <p>Welcome to {companyName}! By accessing or using our website and services, you agree to be bound by these Terms of Service and our Privacy Policy. Please read these terms carefully.</p>

                <h2>1. Acceptance of Terms</h2>
                <p>By using our website and services, you agree to these Terms of Service. If you do not agree with any part of these terms, you must not use our services.</p>

                <h2>2. Changes to Terms</h2>
                <p>We reserve the right to update or modify these terms at any time. Changes will be posted on this page with an updated effective date. Continued use of our services after changes constitutes acceptance of the new terms.</p>

                <h2>3. User Responsibilities</h2>
                <p>You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. Notify us immediately of any unauthorized use or security breach related to your account.</p>

                <h2>4. Content and Intellectual Property</h2>
                <p>All content on our website, including text, graphics, logos, and images, is the property of {companyName} or its licensors and is protected by copyright and other intellectual property laws. You may not use, reproduce, or distribute any content without our explicit permission.</p>

                <h2>5. Limitation of Liability</h2>
                <p>We are not liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use or inability to use our services. Our liability is limited to the maximum extent permitted by law.</p>

                <h2>6. Indemnification</h2>
                <p>You agree to indemnify and hold harmless {companyName} and its affiliates from any claims, liabilities, damages, losses, or expenses, including legal fees, arising out of your use of our services or violation of these terms.</p>

                <h2>7. Governing Law</h2>
                <p>These terms are governed by and construed in accordance with the laws of Switzerland. Any disputes arising under these terms will be resolved in the courts of Winterthur.</p>

                <h2>8. Termination</h2>
                <p>We reserve the right to terminate or suspend your access to our services at our sole discretion, without notice, for conduct that we believe violates these terms or is harmful to our users or services.</p>

                <h2>9. Contact Us</h2>
                <p>If you have any questions or concerns about these Terms of Service, please contact us at {email}.</p>
            </PaddingContainer>
        </MaxWidth>
    );
};

export default TermsOfService;
