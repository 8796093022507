import React, { useEffect, useState } from 'react';
import styles from './UserProfile.module.css';
import ProfilePicture from './ProfilePicture';

const UserProfile = ({ user, profilePictureWidth }) => {
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [description, setDescription] = useState(null);
    useEffect(() => {
        if (user) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setDescription(user.description);
        }
    }, [user]);

    return (
        <div className={styles.userProfile}>
            <ProfilePicture user={user} width={profilePictureWidth}></ProfilePicture>
            <div className={styles.userInfo}>
                <div className={styles.nameContainer} style={{fontSize: Math.max(14, profilePictureWidth/6)}}>
                    {firstName} {lastName}
                </div>
                <div className={styles.descriptionContainer}>
                    {description}
                </div>
            </div>
        </div>
    );
};

export default UserProfile;
