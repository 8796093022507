import React, { useState } from 'react';
import styles from './Menu.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListCounter from './ListCounter';

const Menu = ({ items, onItemClick }) => {
    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemClick = (item) => {
        if (!item.submenuItems) {
            setSelectedItem(item);
            onItemClick(item);
        }
    };

    const renderMenuItems = (items) => {
        return items.map((item, index) => (
            <div key={index}>
                <div
                    className={`${item.submenuItems && item.submenuItems.length > 0 ? styles.parentItem : styles.menuItem} ${selectedItem === item ? styles.selected : ''}`}
                    onClick={() => handleItemClick(item)}
                >
                    {item.icon && <FontAwesomeIcon icon={item.icon} className={styles.icon}/>}
                    {item.svg}
                    <div className={styles.title}>{item.label} {!!item.counter && <ListCounter listLength={item.counter}></ListCounter>}</div>
                </div>
                {item.submenuItems && item.submenuItems.length > 0 && (
                    <div className={styles.submenu}>
                        {renderMenuItems(item.submenuItems)}
                    </div>
                )}
            </div>
        ));
    };

    return (
        <div className={styles.menu}>
            {renderMenuItems(items)}
        </div>
    );
};

export default Menu;
