const _fetch = async (url, method = 'GET', data = null, headers = {}) => {
    try {
        // Construct the options object for fetch
        const options = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                ...headers
            },
            credentials: 'include' // Include credentials for cross-origin requests
        };

        // If there is data to send, add it to the options
        if (data) {
            options.body = JSON.stringify(data);
        }

        // Perform the fetch request
        const response = await fetch(url, options);

        // Parse the response as JSON
        const responseData = await response.json();

        // Check if the response was not OK
        if (!response.ok) {
            // Attach status and message to the error
            const error = new Error(responseData.message || 'Something went wrong');
            error.status = response.status;
            throw error;
        }

        // Return the parsed response data
        return responseData;
    } catch (error) {
        // Attach status and message to the error
        if (!error.status) {
            // Default to a 500 status code if none is provided
            error.status = 500;
        }
        throw error;
    }
};

export default _fetch;
