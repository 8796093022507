import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './Checkbox.module.css'; // Import the CSS module

const Checkbox = ({ label, isSmallLabel = true, checked = false, onChange, stopPropagation = true }) => {
    const handleCheckboxChange = (event) => {
        if (stopPropagation) {
            event.stopPropagation(); // Stops the click event from bubbling up
        }

        // Toggle the checked value
        const newCheckedValue = !checked;

        // Call the onChange function passed by the parent
        if (onChange) {
            onChange(newCheckedValue);
        }
    };

    return (
        <div className={styles.checkboxContainer}>
            <label className={styles.checkboxLabel} onClick={handleCheckboxChange}>
                <div className={`${styles.checkboxBox} ${checked ? styles.checked : ''}`}>
                    {checked && <FontAwesomeIcon icon={faCheck} className={styles.checkboxIcon} />}
                </div>
                {!isSmallLabel && label && <span className={styles.checkboxText}>{label}</span>}
            </label>
            {isSmallLabel && label && <small className={styles.smallLabel}>{label}</small>}
        </div>
    );
};

export default Checkbox;
