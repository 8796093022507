import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ActionButton.module.css';

const ActionButton = ({ icon, label, onClick, isSmall = false }) => {
  return (
    <button
      className={`${styles.actionButton} ${isSmall ? styles.small : ''}`}
      onClick={onClick}
      title={label}
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  );
};

export default ActionButton;
