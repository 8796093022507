const compressImage = async (file, maxSize = 300 * 1024, initialQuality = 0.7) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async (event) => {
            const img = new Image();
            img.src = event.target.result;

            img.onload = async () => {
                const canvas = document.createElement('canvas');
                const maxWidth = 800; // Adjust maximum width as needed
                const maxHeight = 600; // Adjust maximum height as needed
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                let quality = initialQuality;
                let base64data;

                do {
                    base64data = canvas.toDataURL('image/png', quality);
                    quality -= 0.05; // Decrease quality by 0.05 each iteration
                } while (base64data.length > maxSize && quality > 0.1);

                const blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/png', quality));
                resolve(new File([blob], file.name, { type: 'image/png' }));
            };
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsDataURL(file);
    });
};

export default compressImage;