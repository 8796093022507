import React, { useEffect, useState } from 'react';
import {
    faSearch,
    faArrowRightArrowLeft,
    faQuestionCircle,
    faCube,
    faEquals,
    faGreaterThanEqual,
    faFloppyDisk,
    faLessThanEqual,
    faBullseye,
    faFileExcel,
    faPlus,
    faFilter,
    fa1,
    fa2,
    fa3,
    faCloud,
    faCircle,
    faFire,
} from '@fortawesome/free-solid-svg-icons';
import FullLayout from '../components/FullLayout';
import styles from './HR.module.css';
import SlidingContainer from '../components/SlidingContainer';
import ActionButtonsContainer from '../components/ActionButtonsContainer';
import SkillCheckForm from "../components/SkillCheckForm";
import InputField from "../components/InputField";
import Button from '../components/Button';
import Spinner from "../components/Spinner";
import UserCard from "../components/UserCard";
import _fetch from '../utils/_fetch';
import Modal from "../components/Modal";
import FullHeightContainer from '../components/FullHeightContainer';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import EmployeeProfile from './EmployeeProfile';
import PaddingContainer from '../components/PaddingContainer';
import Organization from '../components/Organization';
import CenteredMessage from '../components/CenteredMessage';
import UserProfile from '../components/UserProfile';
import FlexContainer from '../components/FlexContainer';
import EmployeeForm from '../components/EmployeeForm';

const HR = ({
    onUserGuideClick,
    onError,
    onSuccess,
    onContextMenu
}) => {
    const [searchQuery, setSearchQuery] = useState(null);
    const [targetProfile, setTargetProfile] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [organization, setOrganization] = useState(null);

    const [isSearchPerfectMatchSCOpen, setIsSearchPerfectMatchSCOpen] = useState(false);
    const [isTargetProfileSCOpen, setIsTargetProfileSCOpen] = useState(false);
    const [isCompareSCOpen, setIsCompareSCOpen] = useState(false);
    const [isTargetProfileFormSCOpen, setIsTargetProfileFormSCOpen] = useState(false);
    const [isSaveTargetProfileModalOpen, setIsSaveTargetProfileModalOpen] = useState(false);
    const [checkedSkills, setCheckedSkills] = useState([]);
    const [isBestFitsSCOpen, setIsBestFitsSCOpen] = useState(false);
    const [isMatching, setIsMatching] = useState(false);
    const [bestFits, setBestFits] = useState([]);
    const [uncheckedTeams, setUncheckedTeams] = useState([]);
    // Candidate states
    const candidateStates = ['new', 'active 1', 'active 2', 'sleep 1', 'sleep 2', 'cancelled'];
    const [checkedCandidateStates, setCheckedCandidateStates] = useState(candidateStates);


    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [isEditEmployeeModalOpen, setIsEditEmployeeModalOpen] = useState(false);

    const buttonData = [
        { icon: faCube, label: 'Target Profiles', onClick: () => setIsTargetProfileSCOpen(true) },
        { icon: faSearch, label: 'Search Perfect Match', onClick: () => setIsSearchPerfectMatchSCOpen(true) },
        { icon: faArrowRightArrowLeft, label: 'Compare', onClick: () => setIsCompareSCOpen(true) },
        // { icon: faCloudArrowUp, label: 'Upload Data to PQForce', onClick: () => alert('Upload Data to PQForce Clicked') },
        { icon: faQuestionCircle, label: 'User Guide', onClick: onUserGuideClick },
    ];

    const searchPerfectMatch = async (searchEngine = 1) => {
        try {
            // Validate that checkedSkills is not empty
            if (!checkedSkills || checkedSkills.length === 0) {
                throw new Error('No skill selected');
            }

            setIsMatching(true);
            setIsBestFitsSCOpen(true);
            setBestFits([]);

            const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/match`, 'POST', {
                searchEngine,
                selectedSkills: checkedSkills,
                uncheckedTeams,
                candidateStates: checkedCandidateStates,
            });

            setBestFits(responseData.perfectMatches);
        } catch (error) {
            onError(error.message);
        } finally {
            setIsMatching(false);
        }
    }


    useEffect(() => {
        const fetchOrganization = async () => {
            setIsLoading(true);
            try {
                const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/hr/organization`, 'GET');
                setOrganization(responseData.data);
            } catch (error) {
                onError(error.message);
            } finally {
                setIsLoading(false);
            }
        }

        fetchOrganization();
    }, []);

    const searchPerfectMatchButtons = [
        { icon: faBullseye, label: 'Compare Target Profile', onClick: () => alert('Compare Target Profile Clicked') },
        { icon: faArrowRightArrowLeft, label: 'Compare Employees/Candidates', onClick: () => { setIsCompareSCOpen(true); } },
        { icon: faFloppyDisk, label: 'Save Target Profile', onClick: () => { setIsSaveTargetProfileModalOpen(true); } },
        { icon: faCube, label: 'Import Target Profile', onClick: () => { setIsTargetProfileSCOpen(true); } },
        { icon: faCloud, label: 'Soft Search', onClick: () => { searchPerfectMatch(1); } },
        { icon: faCircle, label: 'Normal Search', onClick: () => { searchPerfectMatch(2); } },
        { icon: faFire, label: 'Hard Search', onClick: () => { searchPerfectMatch(3); } },
        // { icon: faFilter, label: 'Filters', onClick: () => { } },
        { icon: faQuestionCircle, label: 'User Guide', onClick: onUserGuideClick }
    ];

    const handleEmployeeClick = (selectedEmployee) => {
        console.log(selectedEmployee);
        setSelectedEmployee(selectedEmployee);
    }

    return (
        <>
            <FullLayout buttons={buttonData}>
                <div className={styles.hr}>
                    <div className={styles.organization}>
                        {isLoading ? <Spinner></Spinner> :

                            <FullHeightContainer>
                                <PaddingContainer>
                                    <InputField
                                        label="Search Employee / Candidate"
                                        value={searchQuery ?? ''}
                                        onChange={(event) => {
                                            setSearchQuery(event.target.value);
                                        }}
                                        icons={[
                                            { icon: faSearch }
                                        ]}
                                    ></InputField>


                                    <Organization
                                        data={organization}
                                        onContextMenu={onContextMenu}
                                        onEmployeeClick={handleEmployeeClick}
                                        onError={onError}
                                        onTeamsChange={setUncheckedTeams}
                                        onCandidatesChange={setCheckedCandidateStates}
                                    ></Organization>

                                </PaddingContainer>
                            </FullHeightContainer>
                        }
                    </div>
                    <div className={styles.employeeContainer}>
                        <FullHeightContainer>
                            {selectedEmployee ?
                                <EmployeeProfile
                                    employee={selectedEmployee}
                                    onError={onError}
                                    onSuccess={onSuccess}
                                    onContextMenu={onContextMenu}
                                    onEditEmployee={() => {
                                        setIsEditEmployeeModalOpen(true);
                                    }}
                                ></EmployeeProfile> :
                                <CenteredMessage message={"Please select an employee to view their profile."}></CenteredMessage>}
                        </FullHeightContainer>
                    </div>
                </div>
            </FullLayout>

            <SlidingContainer
                isOpen={isSearchPerfectMatchSCOpen}
                onClose={() => setIsSearchPerfectMatchSCOpen(false)}
            >
                <FullHeightContainer>
                    <SkillCheckForm
                        onError={onError}
                        moreButtons={searchPerfectMatchButtons}
                    ></SkillCheckForm>
                </FullHeightContainer>
            </SlidingContainer>

            <SlidingContainer
                isOpen={isTargetProfileSCOpen}
                onClose={() => setIsTargetProfileSCOpen(false)}
            >
                <h2>Target Profiles</h2>
                <ActionButtonsContainer buttons={[
                    { icon: faFileExcel, label: 'Import Profile', onClick: () => { } },
                    { icon: faPlus, label: 'New Target Profile', onClick: () => { setIsTargetProfileFormSCOpen(true); } }
                ]}
                ></ActionButtonsContainer>
            </SlidingContainer>

            <SlidingContainer
                isOpen={isCompareSCOpen}
                onClose={() => setIsCompareSCOpen(false)}
                width='80vw'
            >
                <h2>Compare</h2>
                {/* Add Compare content here */}
            </SlidingContainer>


            <SlidingContainer
                isOpen={isTargetProfileFormSCOpen}
                onClose={() => { setIsTargetProfileFormSCOpen(false); }}
            >
                <FullHeightContainer>
                    <form>
                        <InputField
                            label="Name"
                            isRequired={true}
                            value={targetProfile?.name}
                            onChange={() => { }}
                        ></InputField>

                        <SkillCheckForm
                            onError={onError}
                        ></SkillCheckForm>

                        <Button type='submit' style={{ width: 'fit-content' }}>Save</Button>
                    </form>
                </FullHeightContainer>
            </SlidingContainer>


            <SlidingContainer
                isOpen={isBestFitsSCOpen}
                onClose={() => { setIsBestFitsSCOpen(false); }}
            >

                {isMatching && <Spinner></Spinner>}

                {bestFits && bestFits.map((employee, index) => {
                    return (
                        <FlexContainer
                            align='center'
                            justify='space-between'
                        >
                            <UserProfile user={employee}></UserProfile>
                            {employee.matchScore && <h3>{employee.matchScore}</h3>}
                        </FlexContainer>
                    )
                })}
                {!isMatching && !bestFits.length && <CenteredMessage message="No best fit found."></CenteredMessage>}
            </SlidingContainer>

            <Modal
                isOpen={isSaveTargetProfileModalOpen}
                onClose={() => { setIsSaveTargetProfileModalOpen(false); }}
            >
                <h2>New Target Profile</h2>
            </Modal>


            {selectedEmployee &&
                <Modal
                    isOpen={isEditEmployeeModalOpen}
                    onClose={() => { setIsEditEmployeeModalOpen(false); }}
                >
                    <h2>Edit Employee</h2>
                    <EmployeeForm
                        employee={selectedEmployee}
                        onSubmit={async (employee) => {
                            try {
                                const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/employee/${employee.id}/edit`, 'PUT', employee);
                            } catch (error) {
                                onError(error.message);
                            }
                        }}
                    ></EmployeeForm>
                </Modal>
            }
        </>
    );
};

export default HR;
