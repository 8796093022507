import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faCheckCircle, faExclamationCircle, faGlobeAmericas, faCog, faInfoCircle, faUserShield } from '@fortawesome/free-solid-svg-icons';

// Main styles
import styles from './App.module.css';

import LoginForm from './auth/LoginForm';
import ResetPasswordForm from './auth/ResetPasswordForm';

// Pages
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import WorkInProgress from './pages/WorkInProgress';
import SkillCheck from "./pages/SkillCheck";

// Components
import Logo from './components/Logo';
import UserProfile from './components/UserProfile';
import SlidingContainer from './components/SlidingContainer';
import Button from './components/Button';
import SocialIcons from './components/SocialIcons';
import Modal from './components/Modal';
import FlexContainer from "./components/FlexContainer";
import ContextMenu from './components/ContextMenu';
import CompanySignupForm from './auth/CompanySignupForm';
import ContactRequest from './pages/ContactRequest';
import _fetch from './utils/_fetch';
import TermsOfService from './pages/TermsOfService';
import Settings from './pages/Settings';

const App = () => {
    const [user, setUser] = useState(null);
    const [isUserSCOpen, setIsUserSCOpen] = useState(false);
    const [isUserGuideSCOpen, setIsUserGuideSCOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const [isSCOpen, setIsSCOpen] = useState(null);

    const [contextMenuButtons, setContextMenuButtons] = useState(null);
    const [contextMenuPosition, setContextMenuPosition] = useState(null);

    const handleError = (errorMessage) => {
        setErrorMessage(errorMessage);
    };

    const handleSuccess = (successMessage) => {
        setSuccessMessage(successMessage);
    };

    const handleContextMenu = (buttons, event) => {
        event.preventDefault(); // Prevent default context menu from appearing
        event.stopPropagation();
        const { clientX: x, clientY: y } = event;
        setContextMenuPosition({ top: y, left: x });
        setContextMenuButtons(buttons);
    };

    const showUserGuide = () => {
        setIsUserGuideSCOpen(true);
    };

    const renderSCContent = () => {
        switch (isSCOpen) {
            case 'settings':
                return <Settings></Settings>;

            default:
                break;
        }
    }

    return (
        <Router>
            {/* <div key={user} className={styles.page}>
                <div className={styles.header}>
                    <Logo />
                    <FlexContainer align='center'>
                        {user && (
                            <div
                            onClick={(event) => {
                                handleContextMenu([
                                    {
                                        icon: faUserShield,
                                        label: 'Admin Panel',
                                        onClick: () => {
                                            // Navigate to Admin Panel
                                            window.location.href = '/admin-panel';
                                        }
                                    },
                                    {
                                        icon: faCog,
                                        label: 'Settings',
                                        onClick: () => {
                                            setIsSCOpen('settings');
                                        }
                                    },
                                    {
                                        icon: faInfoCircle,
                                        label: 'Help & Support',
                                        onClick: () => {

                                        }
                                    },
                                    {
                                        icon: faArrowRightFromBracket,
                                        label: 'Logout',
                                        onClick: async () => {
                                            try {
                                                await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/auth/logout`, 'POST');
                                                localStorage.clear();
                                                setUser(null);
                                            } catch (error) {
                                                handleError(error.message);
                                            }
                                        }
                                    }
                                ], event);
                            }}
                        >
                            <UserProfile user={user} profilePictureWidth={45} />
                        </div>
                        )}
                        {!user && <LoginButton />}
                        <FontAwesomeIcon
                            icon={faGlobeAmericas}
                            className={styles.langButton}
                            onClick={(event) => {
                                handleContextMenu([
                                    { label: "English" },
                                    { label: "Deutsch" },
                                    { label: "Español" },
                                    { label: "Français" },
                                    { label: "Italiano" },
                                    { label: "Português" },
                                    { label: "Türkçe" },
                                    { label: "中文" },
                                    { label: "日本語" },
                                    { label: "한국어" }
                                ], event);
                            }}
                        />

                    </FlexContainer>

                </div>
                <div className={styles.main}>
                    <Routes>
                        <Route path="/" element={<LoginForm onError={handleError} onSuccess={handleSuccess} onLogin={setUser} />} />
                        <Route path="/login" element={<LoginForm onError={handleError} onSuccess={handleSuccess} onLogin={setUser} />} />
                        <Route path="/signup" element={<CompanySignupForm></CompanySignupForm>}></Route>
                        <Route path="/reset-password" element={<ResetPasswordForm />} />
                        <Route path="/home" element={<Home user={user} onError={handleError} onSuccess={handleSuccess} onContextMenu={handleContextMenu} onUserGuideClick={showUserGuide} />} />
                        <Route path="/skill-check/:token" element={<SkillCheck onError={handleError} onSuccess={handleSuccess} onUserGuideClick={showUserGuide}></SkillCheck>} />
                        <Route path="/contact-request/:token" element={<ContactRequest onError={handleError} onSuccess={handleSuccess}></ContactRequest>} />
                        <Route path="/terms" element={<TermsOfService></TermsOfService>}></Route>
                        <Route path="/*" element={<NotFound />} />
                    </Routes>
                </div>
            </div> */}


            <div className={styles.page}>
                <div className={styles.header}>

                    <Logo />
                    <FlexContainer align='center'>
                        {user && (
                            <div
                                onClick={(event) => {
                                    handleContextMenu([
                                        {
                                            icon: faUserShield,
                                            label: 'Admin Panel',
                                            onClick: () => {
                                                // Navigate to Admin Panel
                                                window.location.href = '/admin-panel';
                                            }
                                        },
                                        {
                                            icon: faCog,
                                            label: 'Settings',
                                            onClick: () => {
                                                setIsSCOpen('settings');
                                            }
                                        },
                                        {
                                            icon: faInfoCircle,
                                            label: 'Help & Support',
                                            onClick: () => {

                                            }
                                        },
                                        {
                                            icon: faArrowRightFromBracket,
                                            label: 'Logout',
                                            onClick: async () => {
                                                try {
                                                    await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/auth/logout`, 'POST');
                                                    localStorage.clear();
                                                    setUser(null);
                                                } catch (error) {
                                                    handleError(error.message);
                                                }
                                            }
                                        }
                                    ], event);
                                }}
                            >
                                <UserProfile user={user} profilePictureWidth={45} />
                            </div>
                        )}
                        {!user && <LoginButton />}
                        <FontAwesomeIcon
                            icon={faGlobeAmericas}
                            className={styles.langButton}
                            onClick={(event) => {
                                handleContextMenu([
                                    { label: "English" },
                                    { label: "Deutsch" },
                                    { label: "Español" },
                                    { label: "Français" },
                                    { label: "Italiano" },
                                    { label: "Português" },
                                    { label: "Türkçe" },
                                    { label: "中文" },
                                    { label: "日本語" },
                                    { label: "한국어" }
                                ], event);
                            }}
                        />

                    </FlexContainer>
                </div>
                <div className={styles.main}>
                    <Routes>
                        <Route path="/" element={<LoginForm onError={handleError} onSuccess={handleSuccess} onLogin={setUser} />} />
                        <Route path="/login" element={<LoginForm onError={handleError} onSuccess={handleSuccess} onLogin={setUser} />} />
                        <Route path="/signup" element={<CompanySignupForm></CompanySignupForm>}></Route>
                        <Route path="/reset-password" element={<ResetPasswordForm />} />
                        <Route path="/home" element={<Home user={user} onError={handleError} onSuccess={handleSuccess} onContextMenu={handleContextMenu} onUserGuideClick={showUserGuide} />} />
                        <Route path="/skill-check/:token" element={<SkillCheck onError={handleError} onSuccess={handleSuccess} onUserGuideClick={showUserGuide}></SkillCheck>} />
                        <Route path="/contact-request/:token" element={<ContactRequest onError={handleError} onSuccess={handleSuccess}></ContactRequest>} />
                        <Route path="/terms" element={<TermsOfService></TermsOfService>}></Route>
                        <Route path="/*" element={<NotFound />} />
                    </Routes>
                </div>
            </div>
            <div className={styles.footer}>
                <SocialIcons links={[]} />
                <div className={styles.links}>
                    {/* <Link to="/privacy">Privacy Policy</Link>
                        <Link to="/terms">Terms of Service</Link>
                        <Link to="/contact">Contact Us</Link> */}
                </div>
                <div className={styles.copyright}>
                    &copy; 2024 cross-ING AG. All rights reserved.
                </div>
            </div>

            <SlidingContainer
                isOpen={isUserGuideSCOpen}
                onClose={() => { setIsUserGuideSCOpen(false); }}
            >
                <h2>User Guide</h2>
                <WorkInProgress></WorkInProgress>
            </SlidingContainer>

            <Modal
                isOpen={!!errorMessage}
                onClose={() => { setErrorMessage(null); }}
            >
                <FlexContainer align={'center'} gap={20}>
                    <FontAwesomeIcon icon={faExclamationCircle} className={styles.exclamationCircle} />
                    <p>{errorMessage}</p>
                </FlexContainer>
            </Modal>

            <Modal
                isOpen={!!successMessage}
                onClose={() => { setSuccessMessage(null); }}
            >
                <FlexContainer align={'center'} gap={20}>
                    <FontAwesomeIcon icon={faCheckCircle} className={styles.exclamationCircle} />
                    <p>{successMessage}</p>
                </FlexContainer>
            </Modal>

            {contextMenuButtons && (
                <ContextMenu
                    buttons={contextMenuButtons}
                    onClose={() => setContextMenuButtons(null)}
                    position={contextMenuPosition}
                />
            )}

            <SlidingContainer
                isOpen={isSCOpen}
                onClose={() => { setIsSCOpen(false); }}
            >
                {renderSCContent()}
            </SlidingContainer>

        </Router>
    );
};

// Component to handle the login navigation inside Router
const LoginButton = () => {
    const navigate = useNavigate();
    return (
        <Button onClick={() => navigate('/login')} style={{ width: 'fit-content' }}>
            Login
        </Button>
    );
};

export default App;
