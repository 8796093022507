import React, { useState, useEffect } from 'react';
import Button from '../components/Button';
import InputField from '../components/InputField';
import Modal from '../components/Modal';
import FullLayout from '../components/FullLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faSearch, faTrash, faCrown, faBan, faEdit, faEye, faEnvelope, faLightbulb, faRotate, faUser, faUsers, faSuitcase } from '@fortawesome/free-solid-svg-icons';
import _fetch from '../utils/_fetch';
import UserProfile from '../components/UserProfile';
import MaxWidth from '../components/MaxWidth';
import ActionButtonsContainer from '../components/ActionButtonsContainer';
import styles from './UserManagement.module.css';
import PaddingContainer from '../components/PaddingContainer';
import FlexContainer from '../components/FlexContainer';
import Checkbox from '../components/Checkbox';

const UserActionControl = ({ icon, checked, onChange, title, iconClass, disabled }) => {
    return (
        <FlexContainer align='center' direction='column' gap={3}>
            <FontAwesomeIcon icon={icon} className={iconClass} />
            <Checkbox
                checked={checked}
                onChange={onChange}
                title={title}
                disabled={disabled}
            />
        </FlexContainer>
    );
};

const UserManagement = ({ user, onError, onSuccess }) => {
    const [users, setUsers] = useState([]);
    const [newUserEmail, setNewUserEmail] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const buttonData = [
        { icon: faUserPlus, label: 'Add User', onClick: () => setIsModalOpen(true) },
    ];

    useEffect(() => {
        if (!user?.isAdmin) {
            return;
        }

        const fetchUsers = async () => {
            try {
                const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/admin/users`, 'GET');
                setUsers(responseData.users);
            } catch (error) {
                onError(error.message);
            }
        };

        fetchUsers();
    }, [onError]);

    const handleAddUser = async (event) => {
        event.preventDefault();
        try {
            const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/admin/user`, 'POST', {
                email: newUserEmail
            });
            setUsers([responseData.user, ...users]);
        } catch (error) {
            onError(error.message);
        } finally {
            setIsModalOpen(false);
        }
    };

    const handleRemoveUser = async (userId) => {
        try {
            await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/admin/user?id=${userId}`, 'DELETE');
            setUsers(users.filter(user => user.id !== userId));
        } catch (error) {
            onError(error.message);
        }
    };

    const handleToggleUserAction = (userId, action) => {
        // Implement the function to handle the toggle action for user permissions
        console.log(`Toggled ${action} for user ${userId}`);
    };

    const filteredUsers = users.filter(user =>
        user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <FullLayout buttons={buttonData}>
                <MaxWidth>
                    <PaddingContainer>
                        <InputField
                            label="Search user"
                            value={searchQuery}
                            onChange={(event) => setSearchQuery(event.target.value)}
                            icons={[{ icon: faSearch }]}
                        />
                        {filteredUsers.length > 0 ? (
                            filteredUsers.map(user => (
                                <div key={user.id} className={styles.userCard}>
                                    <FlexContainer align='center' gap={10}>
                                        <UserProfile user={user}></UserProfile>
                                    </FlexContainer>
                                    <FlexContainer align='center'>
                                        <div className={styles.actionButtonsContainer}>
                                            <ActionButtonsContainer
                                                buttons={[
                                                    {
                                                        icon: faTrash,
                                                        label: 'Remove',
                                                        onClick: () => handleRemoveUser(user.id)
                                                    }
                                                ]}
                                                isSmall={true}
                                            />
                                        </div>

                                        <UserActionControl
                                            icon={faCrown}
                                            checked={!!user.isAdmin}
                                            onChange={() => handleToggleUserAction(user.id, 'admin')}
                                            title="If checked, user has admin rights."
                                            iconClass={`${styles.icon} ${!!user.isAdmin && styles.admin}`}
                                        />
                                        {/* <UserActionControl
                                            icon={faBan}
                                            checked={!!user.isBanned}
                                            onChange={() => handleToggleUserAction(user.id, 'banned')}
                                            title="If checked, user is banned and cannot log in."
                                            iconClass={`${styles.icon} ${!!user.isBanned && styles.banned}`}
                                        />
                                        <UserActionControl
                                            icon={faLightbulb}
                                            checked={!!user.canUpdateSkills}
                                            onChange={() => handleToggleUserAction(user.id, 'updateSkills')}
                                            title="If checked, user can edit skills."
                                            iconClass={`${styles.icon} ${!!user.canUpdateSkills && styles.updateSkills}`}
                                        />
                                        <UserActionControl
                                            icon={faSuitcase}
                                            checked={!!user.canSeeCandidates}
                                            onChange={() => handleToggleUserAction(user.id, 'seeCandidates')}
                                            title="If checked, user can see candidates."
                                            iconClass={`${styles.icon} ${!!user.canSeeCandidates && styles.seeCandidates}`}
                                        />
                                        <UserActionControl
                                            icon={faRotate}
                                            checked={!!user.canSendSkillUpdateRequests}
                                            onChange={() => handleToggleUserAction(user.id, 'sendSkillUpdateRequests')}
                                            title="If checked, user can send skill update requests."
                                            iconClass={`${styles.icon} ${!!user.canSendSkillUpdateRequests && styles.sendSkillUpdateRequests}`}
                                        /> */}
                                    </FlexContainer>
                                </div>
                            ))
                        ) : (
                            <p>No users found.</p>
                        )}
                    </PaddingContainer>
                </MaxWidth>
            </FullLayout>

            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                <h2>Add User</h2>
                <div>Search for the employee you want to give access.</div>
                <InputField
                    id="newUserEmail"
                    type="email"
                    value={newUserEmail}
                    onChange={(e) => setNewUserEmail(e.target.value)}
                    label="Email or name"
                    required
                    icon={faSearch}
                />
                <Button onClick={handleAddUser}>Add User</Button>
            </Modal>
        </>
    );
};

export default UserManagement;
