import React, { useEffect, useState } from "react";
import DynamicComponentRenderer from "./DynamicComponentRenderer";
import ActionButtonsContainer from "../components/ActionButtonsContainer";
import SkillTree from "../components/SkillTree";
import WorkInProgress from "../pages/WorkInProgress";
import { faExpand, faCompress, faCheckSquare, faTimesCircle, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import _fetch from "../utils/_fetch";
import FlexContainer from "./FlexContainer";
import InputField from "./InputField";
import styles from "./SkillCheckForm.module.css";
import RelativeContainer from "./RelativeContainer";
import CenteredMessage from "./CenteredMessage";
import FullHeightContainer from "./FullHeightContainer";

const SkillCheckForm = ({ onError, onSuccess, isInEditingMode = false, moreButtons = [], preloadedSkills, showPreloadedSkillsOnly = false }) => {
    const [skills, setSkills] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        const fetchSkills = async () => {
            try {
                const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/skills`, "GET");
                setSkills(responseData.skills);
            } catch (error) {
                onError(error.message);
            }
        };

        fetchSkills();
    }, [onError]);

    // Filter skills based on both name and description
    const filteredSkills = skills.filter(skill =>
        skill.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (skill.description && skill.description.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    const buttons = [
        { icon: faExpand, label: "Expand All", onClick: () => console.log("Expand all sections") },
        { icon: faCompress, label: "Collapse All", onClick: () => console.log("Collapse all sections") },
    ];

    if (isInEditingMode) {
        buttons.push({ icon: faPlus, label: "Add Root Skill", onClick: () => console.log("Add Root Skill") });
    } else {
        if (!showPreloadedSkillsOnly) {
            buttons.push(
                { icon: faCheckSquare, label: "Show Checked Skills", onClick: () => console.log("Show checked skills") },
                { icon: faTimesCircle, label: "Clear All", onClick: () => console.log("Clear all selections") }
            );
        }
    }

    return (
        <FullHeightContainer>
            <FlexContainer justify="space-between">
                <ActionButtonsContainer buttons={buttons} />
                <ActionButtonsContainer buttons={moreButtons} />
            </FlexContainer>

            <InputField
                label="Search Skill"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
                icons={[{ icon: faSearch }]}
            />
            <RelativeContainer>
                <div className={styles.filteredSkillsContainer}>
                    {/* Conditionally display skills based on search query */}
                    {searchQuery && (
                        <>
                            {filteredSkills.length > 0 ? (
                                <>
                                    {filteredSkills.map(skill => (
                                        <div key={skill.id} className={styles.filteredSkill} onClick={() => console.log(`Scroll to skill: ${skill.name}`)}>
                                            <div>{skill.name}</div>
                                            <small>{skill.description}</small>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <CenteredMessage
                                    message="No matching skills found"
                                    style={{ fontWeight: 'bold' }}
                                ></CenteredMessage>
                            )}
                        </>
                    )}
                </div>
            </RelativeContainer>

            <DynamicComponentRenderer
                components={[
                    {
                        label: "Hard Skills",
                        component: (
                            skills.length > 0 ? (
                                <SkillTree
                                    skills={skills}
                                    isInEditingMode={isInEditingMode}
                                    preloadedSkills={preloadedSkills}
                                    showPreloadedSkillsOnly={showPreloadedSkillsOnly}
                                />
                            ) : (
                                <p>No skills available.</p>
                            )
                        )
                    },
                    // { label: "Soft Skills", component: <WorkInProgress /> }
                ]}
            />
        </FullHeightContainer>
    );
};

export default SkillCheckForm;
