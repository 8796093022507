import React, { useEffect, useState } from 'react';
import styles from './DynamicComponentRendererWithSideMenu.module.css';
import Menu from './Menu';
import FullHeightContainer from './FullHeightContainer';

const DynamicComponentRendererWithSideMenu = ({ menu }) => {
    const [selectedComponent, setSelectedComponent] = useState(null);

    const onItemClick = (item) => {
        setSelectedComponent(() => item.component);
    };

    useEffect(() => {
        // Reset the selected component when the menu changes
        setSelectedComponent(null);
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.menu}>
                <Menu items={menu} onItemClick={onItemClick} />
            </div>
            <div className={styles.content}>
                <FullHeightContainer>
                    {selectedComponent || <p>Please select an option from the menu.</p>}
                </FullHeightContainer>
            </div>
        </div>
    );
};

export default DynamicComponentRendererWithSideMenu;
