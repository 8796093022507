import React from 'react';
import styles from './CenteredMessage.module.css';

const CenteredMessage = ({ message, style }) => {
  return (
    <div className={styles.centeredMessage} style={style}>
      {message}
    </div>
  );
};

export default CenteredMessage;
