import React from 'react';
import Form from '../components/Form';
import MaxWidth from '../components/MaxWidth';
import { Link } from 'react-router-dom';
import Button from '../components/Button';

const SignupForm = () => {
    const fields = [
        { name: 'Email', key: 'email', isRequired: true, isDisabled: false, type: 'email' },
        { name: 'Password', key: 'password', isRequired: true, isDisabled: false, type: 'password' },
        { name: 'Repeat Password', key: 'repeatPassword', isRequired: true, isDisabled: false, type: 'password' },
    ];

    const handleSignupSubmit = (data) => {
        // Handle signup submission
        console.log('Signup Data:', data);
        // Perform further actions like API call here
    };

    return (
        <MaxWidth maxWidth={450}>
            <h2>Create your Account</h2>
            <Form fields={fields} submitButtonLabel={"Sign up"} onSubmit={handleSignupSubmit} />
            <p>
                By signing up, you agree to our <Link to="/terms" className={"classical-link"}>Terms of Service</Link> and <Link to="/privacy" className={"classical-link"}>Privacy Policy</Link>.
            </p>
            <p>
                Already have an account?
                <Button>Login</Button>
            </p>
        </MaxWidth>
    );
};

export default SignupForm;
