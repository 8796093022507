const _maturityOptions = (maturity = 'Professional') => {
    return [
        { value: 'professional', label: 'Professional', default: maturity === 'Professional' },
        { value: 'senior', label: 'Senior', default: maturity === 'Senior' },
        { value: 'expert', label: 'Expert', default: maturity === 'Expert' },
        { value: 'senior_expert', label: 'Senior Expert', default: maturity === 'Senior Expert' },
        { value: 'principal', label: 'Principal', default: maturity === 'Principal' },
        { value: 'senior_principal', label: 'Senior Principal', default: maturity === 'Senior Principal' },
    ];
};

export default _maturityOptions;