import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faExclamation } from '@fortawesome/free-solid-svg-icons';
import styles from './Select.module.css';

const Select = ({ label, isRequired, style, errors, options, onOptionSelect, ...props }) => {
    const [focused, setFocused] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        onOptionSelect(e.target.value);
    };

    return (
        <div className={styles.container} style={style ?? null}>
            <label className={styles.label}>
                {label}
                {isRequired && <span className={styles.mandatoryStar}>*</span>}
            </label>
            <div className={`${styles.inputContainer} ${focused ? styles.focused : ''}`}>
                <select
                    className={`${styles.selectField} ${errors && errors.length > 0 ? styles.error : ''}`}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleOptionChange}
                    value={selectedOption || ''}
                    {...props}
                >
                    <option value="" disabled hidden>Select an option</option>
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>{option.label}</option>
                    ))}
                </select>
                <div className={styles.iconContainer}>
                    <FontAwesomeIcon icon={faChevronDown} />
                </div>
            </div>
        </div>
    );
};

export default Select;
