import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import styles from './ProfilePicture.module.css';

const ProfilePicture = ({ user, width }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [profilePicture, setProfilePicture] = useState(null);

    useEffect(() => {
        if (!user) {
            return;
        }
        setFirstName(user.firstName);
        setLastName(user.lastName);
        if (user?.profilePicture) {
            setProfilePicture(user.profilePicture);
        } else {
            setProfilePicture(null);
        }
    }, [user]);

    return (
        <div className={styles.profilePictureContainer} style={{ width: width, height: width, minWidth: width, minHeight: width }}>
            {profilePicture ? (
                <>
                    <img src={`data:image/png;base64,${profilePicture}`} alt={`${firstName} ${lastName}`} className={styles.profilePicture} />
                </>
            ) : (
                <FontAwesomeIcon icon={faUser} className={styles.defaultProfileIcon} style={{ width: `${width / 2}px`, height: `${width / 2}px` }} />
            )}
        </div>
    );
};

export default ProfilePicture;
