import React, { useState } from 'react';
import Checkbox from './Checkbox';
import styles from './RadioGroup.module.css'; // Import the CSS module

const RadioGroup = ({ labels, isSmallLabel, name, onChange }) => {
    const [selectedValue, setSelectedValue] = useState(null);

    const handleCheckboxChange = (label) => {
        const newValue = label === selectedValue ? null : label; // If the same is clicked, uncheck
        setSelectedValue(newValue);

        // Call the onChange function and pass the new value
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <div className={styles.radioGroupContainer}>
            {labels.map((label) => (
                <div key={label} className={styles.checkboxContainer}>
                    <Checkbox
                        label={label}
                        isSmallLabel={isSmallLabel}
                        checked={selectedValue === label}
                        onChange={() => handleCheckboxChange(label)}
                        stopPropagation={true} // Ensure event propagation is controlled
                    />
                </div>
            ))}
        </div>
    );
};

export default RadioGroup;
