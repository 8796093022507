import React from 'react';
import IconButton from '../components/IconButton'; // Import the IconButton component
import styles from './FullLayout.module.css'; // Import CSS Module for FullLayout styles
import FullHeightContainer from './FullHeightContainer';

const FullLayout = ({ buttons, children }) => {
    return (
        <div className={styles.container}>
            <div className={styles.centerPane}>
                {children} {/* Render children in the center pane */}
            </div>
            <div className={styles.rightPane}>
                <FullHeightContainer>
                    {buttons.map((button, index) => (
                        <IconButton
                            key={index}
                            icon={button.icon}
                            label={button.label}
                            onClick={button.onClick}
                        />
                    ))}
                </FullHeightContainer>
            </div>
        </div>
    );
};

export default FullLayout;
