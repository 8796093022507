import React from 'react';
import { faQuestionCircle, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import FullLayout from '../components/FullLayout'; // Import FullLayout component
import MaxWidth from "../components/MaxWidth";

import SkillCheckForm from "../components/SkillCheckForm";

const SkillManagement = ({ onError, onSuccess, onUserGuideClick = () => {} }) => {

    const buttonData = [
        // { icon: faCloudArrowUp, label: 'Update Skills to PQForce', onClick: () => alert('Update Skills to PQForce Clicked') },
        { icon: faQuestionCircle, label: 'User Guide', onClick: onUserGuideClick }
    ];

    return (
        <FullLayout buttons={buttonData}>
            <MaxWidth>
                <SkillCheckForm
                    onError={onError}
                    onSuccess={onSuccess}
                    isInEditingMode={true}
                ></SkillCheckForm>
            </MaxWidth>
        </FullLayout>
    );
};

export default SkillManagement;
