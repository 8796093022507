import React from 'react';
import logoImage from '../images/cross-ING.webp';
import styles from './Logo.module.css';

const Logo = () => {
    return (
        <div className={styles.logo}>
            <img src={logoImage} alt="Logo" className={styles.logoImage} />
        </div>
    );
};

export default Logo;
