import React, { useState } from 'react';
import styles from './InputField.module.css'; // Import CSS Module
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const InputField = ({ id, type, value, onChange, label, required, icons, style }) => {
    const [showPassword, setShowPassword] = useState(false);

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(prev => !prev);
    };

    // Determine the input type based on state
    const inputType = type === 'password' ? (showPassword ? 'text' : 'password') : type;

    return (
        <div className={styles.formGroup}>
            <label htmlFor={id}>
                {label} {required && <span style={{ color: 'red' }}>*</span>}
            </label>
            <div className={styles.inputContainer}>
                <input
                    id={id}
                    type={inputType}
                    value={value ?? ''}
                    onChange={onChange}
                    required={required}
                    className={styles.input}
                    style={style}
                />
                <div className={styles.iconContainer}>
                    {type === 'password' && (
                        <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            className={styles.icon}
                            onClick={togglePasswordVisibility}
                        />
                    )}
                    {icons?.map((icon, index) => (
                        <FontAwesomeIcon
                            key={index}
                            icon={icon.icon}
                            className={styles.icon}
                            onClick={icon?.callback}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default InputField;
