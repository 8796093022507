import React, { useState, useEffect } from 'react';
import Button from '../components/Button'; // Import the Button component
import InputField from '../components/InputField'; // Import the InputField component
import styles from './Settings.module.css'; // Import CSS Module for settings styles
import MaxWidth from '../components/MaxWidth';
import ProfilePicture from '../components/ProfilePicture';
import _fetch from '../utils/_fetch';
import Modal from '../components/Modal';
import Spinner from '../components/Spinner';
import PaddingContainer from '../components/PaddingContainer';
import Verify2FACode from '../auth/Verify2FACode';
import VerifyPassword from '../auth/VerifyPassword';

const Settings = ({ user, onError, onSuccess, onDelete }) => {
    const [verificationCode, setVerificationCode] = useState('');
    const [isCodeRequested, setIsCodeRequested] = useState(false);

    const [isTwoFAEnabled, setIsTwoFAEnabled] = useState(user?.is2FAEnabled);
    const [isLoadingQRCode, setIsLoadingQRCode] = useState(false);
    const [isTwoFAModalOpen, setIsTwoFAModalOpen] = useState(false);
    const [qrCode, setQRCode] = useState(null);
    const [otp, setOtp] = useState(null);
    const [isVerify2FAModalOpen, setIsVerify2FAModalOpen] = useState(false);
    const [email, setEmail] = useState(null);
    const [isVerifyPasswordModalOpen, setIsVerifyPasswordModalOpen] = useState(false);

    const handleRequestCode = async () => {
        try {
            const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/auth/request-deletion-code`, 'GET');
            setIsCodeRequested(true);
        } catch (error) {
            onError(error.message);
        }
    };

    const handleConfirmDeletion = async (event) => {
        event.preventDefault();

        try {
            const responseData = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/auth/confirm-deletion`, 'POST');
            onSuccess(responseData.message);
            setIsCodeRequested(false);

            onDelete();

        } catch (error) {
            onError(error.message);
        }
    };

    const handleEnableTwoFA = async () => {
        try {

            if (!isTwoFAEnabled) {
                setIsTwoFAModalOpen(true);
                setIsLoadingQRCode(true);
                const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/auth/2fa/qr`);
                setQRCode(responseData.qrCode);
                setOtp(responseData.otp);
                setEmail(responseData.email);

                console.log(responseData);
            }

        } catch (error) {
            onError(error.message);
        } finally {
            setIsLoadingQRCode(false);
            // setIsTwoFAModalOpen(false);
        }
    };

    const handleDisableTwoFA = async () => {

    };


    const handleRequestPasswordReset = () => {

    };

    return (
        <>
            <MaxWidth>
                <PaddingContainer>
                    <section className={styles.personalInfoSection}>
                        <h2>Personal Information</h2>
                        <ProfilePicture user={user} width={200}></ProfilePicture>
                        <div className={styles.info}>
                            <p><strong>Email:</strong> {user?.email}</p>
                            <p><strong>First Name:</strong> {user?.firstName}</p>
                            <p><strong>Last Name:</strong> {user?.lastName}</p>
                            {/* Add buttons or forms to update these fields if required */}
                        </div>
                    </section>


                    <section className={styles.passwordResetSection}>
                        <h2>Reset Password</h2>
                        <p>
                            If you need to reset your password, click the button below to request a password reset link. A link will be sent to your email address.
                        </p>
                        <Button onClick={handleRequestPasswordReset} className={styles.resetButton}>Request Password Reset Link</Button>
                    </section>

                    <section className={styles.twoFASection}>
                        <h2>Two-Factor Authentication</h2>
                        <p className={styles.info}>
                            {isTwoFAEnabled
                                ? 'Two-Factor Authentication is currently enabled. You can disable it if needed.'
                                : 'Two-Factor Authentication is currently disabled. Enable it for enhanced security.'}
                        </p>
                        {isTwoFAEnabled ? (
                            <Button onClick={() => { setIsVerifyPasswordModalOpen(true); }} className={styles.twoFAButton}>Disable 2FA</Button>
                        ) : (
                            <Button onClick={handleEnableTwoFA} className={styles.twoFAButton}>Enable 2FA</Button>
                        )}
                    </section>

                    {user?.isAdmin && <section className={styles.deletionSection}>
                        {!isCodeRequested ? (
                            <>
                                <h2>Request Account Deletion</h2>
                                <p className={styles.info}>
                                    To request account deletion, click the button below. A verification code will be sent to your email.
                                </p>
                                <Button onClick={handleRequestCode} className={styles.requestCodeButton}>Request Deletion Code</Button>
                            </>
                        ) : (
                            <form onSubmit={handleConfirmDeletion} className={styles.form}>
                                <h2>Confirm Account Deletion</h2>
                                <p className={styles.info}>
                                    Enter the verification code sent to your email to confirm account deletion.
                                </p>
                                <InputField
                                    id="verificationCode"
                                    type="text"
                                    value={verificationCode}
                                    onChange={(e) => setVerificationCode(e.target.value)}
                                    label="Verification Code"
                                    required
                                />
                                <Button type="submit" className={styles.deleteButton}>Confirm Deletion</Button>
                            </form>
                        )}
                    </section>}
                </PaddingContainer>
            </MaxWidth>

            <Modal
                isOpen={isTwoFAModalOpen}
                onClose={() => setIsTwoFAModalOpen(false)}
            >
                {isLoadingQRCode ? (
                    <Spinner />
                ) : (
                    <MaxWidth maxWidth='500px'>
                        <h2>Scan the QR Code</h2>
                        <p>
                            Please scan the QR code with your authentication app (like Google Authenticator or Microsoft Authenticator).
                            This will add your account to the app and allow you to generate 2FA codes for secure login.
                        </p>
                        <img src={qrCode} className={styles.qrCode} />
                        <div>
                            <Button onClick={() => {
                                setIsVerify2FAModalOpen(true);
                            }}>
                                Next
                            </Button>
                        </div>
                    </MaxWidth>
                )}
            </Modal>

            <Modal
                isOpen={isVerify2FAModalOpen}
                onClose={() => { setIsVerify2FAModalOpen(false); }}
            >
                <Verify2FACode
                    onError={onError}
                    onSuccess={onSuccess}
                    onCodeVerified={async () => {
                        setIsTwoFAEnabled(true);
                        setIsVerify2FAModalOpen(false);
                        setIsTwoFAModalOpen(false);
                        setQRCode(null);
                        setOtp(null);
                        setEmail(null);
                    }}
                    email={email}
                    otp={otp}
                ></Verify2FACode>
            </Modal>


            <VerifyPassword
                isOpen={isVerify2FAModalOpen}
                onClose={() => { setIsVerifyPasswordModalOpen(false); }}
                onPasswordVerified={(responseData) => {

                }}
            ></VerifyPassword>
        </>
    );
};

export default Settings;
