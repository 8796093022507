import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./SlidingContainer.module.css";
import PaddingContainer from "./PaddingContainer";
import FullHeightContainer from "./FullHeightContainer";
import Button from "./Button";

const SlidingContainer = ({ isOpen, onOpen, onClose, children, width = "50%", slideFrom = "right" }) => {
    const containerClass = `${styles.slidingContainer} ${isOpen ? styles.open : ''} ${slideFrom === "left" ? styles.fromLeft : styles.fromRight}`;
    const contentStyle = slideFrom === "left" ? { left: 0 } : { right: 0, left: "auto" }; // Ensure content is on the right side

    return (
        <>
            {!!onOpen && <FontAwesomeIcon icon={faBars} onClick={onOpen} className={styles.barsIcon}></FontAwesomeIcon>}
            <div className={containerClass}>
                <div className={styles.overlay} onClick={onClose}></div>
                <div className={styles.content} style={{ ...contentStyle, width }}>
                    <FullHeightContainer>
                        <PaddingContainer>
                            {/* <Button onClick={() =>{onClose();}}>Close</Button> */}
                            <div className={styles.childrenContainer}>
                                {children}
                            </div>
                        </PaddingContainer>
                    </FullHeightContainer>
                </div>
            </div>
        </>
    )
};

export default SlidingContainer;
