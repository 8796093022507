import React, { useState } from 'react';
import NavigationBar from './NavigationBar';
import styles from './DynamicComponentRenderer.module.css';

const DynamicComponentRenderer = ({ components, style }) => {
    const [selectedComponent, setSelectedComponent] = useState(0);

    const handleNavigationItemClick = (index) => {
        setSelectedComponent(index);
    };

    return (
        <div className={styles.dynamicComponentRenderer} style={style}>
            <div className={styles.navigationBarContainer}>
                <NavigationBar
                    items={components.map((item, index) => ({
                        label: item.label,
                        callback: () => handleNavigationItemClick(index),
                    }))}
                />
            </div>
            <div className={styles.container}>
                {components.map((item, index) => (
                    <React.Fragment key={index}>
                        {selectedComponent === index && item.component}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default DynamicComponentRenderer;
