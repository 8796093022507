// PaddingContainer.js
import React from 'react';
import styles from './PaddingContainer.module.css'; // You can add styles in a separate CSS module

const PaddingContainer = ({ padding, children }) => {
    return (
        <div className={styles['padding-container']} style={{padding: padding}}>
            {children}
        </div>
    );
};

export default PaddingContainer;
