import React, { useState } from 'react';
import styles from './JobFair.module.css'; // Import CSS Module for JobFair styles
import MaxWidth from "../components/MaxWidth";
import Button from "../components/Button";
import { useNavigate } from 'react-router-dom';
import EmployeeForm from '../components/EmployeeForm';
import _fetch from '../utils/_fetch';
import PaddingContainer from '../components/PaddingContainer';

const JobFair = ({ onError }) => {

    const [companyName, setCompanyName] = useState('cross-ING AG');

    const [step, setStep] = useState(1);
    const [student, setStudent] = useState({
        firstName: '',
        lastName: '',
        email: '',
        maturity: '',
        description: '',
        profilePicture: null
    });
    const [registrationType, setRegistrationType] = useState('');
    const navigate = useNavigate();

    const handleNext = () => {
        setStep(2);
    };

    const handleBack = () => {
        setStep(1);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStudent({
            ...student,
            [name]: value
        });
    };

    const handleRegistrationType = (type) => {
        setRegistrationType(type);
        handleNext();
    };

    return (
        <PaddingContainer>
            <MaxWidth>
                {step === 1 && (
                    <>
                        <h1>What are you looking for today?</h1>
                        <div className={styles.optionContainer}>
                            <div className={styles.option}>
                                <h3>Looking for an Internship?</h3>
                                <p>Embark on an exciting journey to gain practical experience and deepen your knowledge through internships or thesis projects. Connect with industry experts and enhance your career prospects.</p>
                                <Button onClick={() => handleRegistrationType('internship')}>Register as a Student</Button>
                            </div>
                            <div className={styles.option}>
                                <h3>Are you interested in a job at {companyName}?</h3>
                                <p>Join our dynamic team at cross-ING AG and take the next step in your professional career. We offer growth opportunities, a collaborative environment, and a chance to make an impact.</p>
                                <Button onClick={() => handleRegistrationType('job')}>Register as a Candidate</Button>
                            </div>
                        </div>
                    </>
                )}
            </MaxWidth>


            {step === 2 && (
                <MaxWidth maxWidth='500px'>
                    <Button onClick={handleBack}>Back</Button>
                    <h2>{registrationType === 'internship' ? 'Student Registration' : 'Candidate Registration'}</h2>
                    <EmployeeForm
                        employee={student}
                        onSubmit={async (student) => {
                            try {
                                const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/${registrationType === 'internship' ? 'student' : 'candidate'}`, 'POST', student);
                                navigate(`/skill-check/token=${responseData.token}`);
                            } catch (error) {
                                onError(error.message);
                            }
                        }}
                        onError={onError}
                    ></EmployeeForm>
                </MaxWidth>
            )}
        </PaddingContainer>
    );
};

export default JobFair;
