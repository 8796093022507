import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faEdit } from "@fortawesome/free-solid-svg-icons";
import styles from "./SkillTree.module.css"; // You'll need to create appropriate styles
import ActionButtonsContainer from "./ActionButtonsContainer";
import RadioGroup from "./RadioGroup";

const SkillTree = ({ skills, isInEditingMode = false, preloadedSkills, showPreloadedSkillsOnly = false }) => {
    const [expanded, setExpanded] = useState(new Set());

    const toggleSkill = (id) => {
        setExpanded(prev =>
            new Set(prev.has(id) ? Array.from(prev).filter(x => x !== id) : [...prev, id])
        );
    };

    const handleEditSkill = (selectedSkill) => {

    };

    const [selectedOption, setSelectedOption] = useState(null);

    const handleRadioChange = (value) => {
        console.log('Selected:', value);
        setSelectedOption(value);
    };

    const renderSkills = (skills, parentId = null) => {
        return skills
            .filter(skill => skill.parentId === parentId)
            .map(skill => {
                const childSkills = skills.filter(child => child.parentId === skill.id);
                const isExpanded = expanded.has(skill.id);

                return (
                    <div key={skill.id} className={styles.skillItem}>
                        <div
                            className={styles.skillHeader}
                            onClick={() => toggleSkill(skill.id)}
                        >
                            <div>
                                {childSkills.length > 0 && (
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className={`${styles.chevron} ${isExpanded ? styles.rotate : ""}`}
                                    />
                                )}
                                {skill.name}
                            </div>
                            {!isInEditingMode && !childSkills.length && 
                                <RadioGroup
                                    labels={['Good', 'Very Good', 'Excellent']}
                                    name={skill.id}
                                    onChange={handleRadioChange}
                                />
                            }
                            {isInEditingMode &&
                                <div className={styles.actionButtonsContainer}>
                                    <ActionButtonsContainer
                                        buttons={[
                                            {
                                                icon: faEdit,
                                                label: "Edit skill",
                                                onClick: (event) => {
                                                    event.stopPropagation();
                                                }
                                            }
                                        ]}
                                        isSmall={true}
                                    ></ActionButtonsContainer>
                                </div>
                            }
                        </div>
                        {isExpanded && childSkills.length > 0 && (
                            <div className={styles.skillChildren}>
                                {renderSkills(skills, skill.id)}
                            </div>
                        )}
                    </div>
                );
            });
    };

    return <div className={styles.skillTree}>{renderSkills(skills)}</div>;
};

export default SkillTree;
