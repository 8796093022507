import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { faUserTie, faBriefcase, faCogs, faGraduationCap, faLightbulb, faUsersCog, faNetworkWired, faDiagramProject } from '@fortawesome/free-solid-svg-icons';
import DynamicComponentRendererWithSideMenu from '../components/DynamicComponentRendererWithSideMenu';

import SkillManagement from "./SkillManagement";
import HR from './HR';
import JobFair from './JobFair';
import Internships from './Internships';
import Settings from './Settings';
import UserManagement from './UserManagement';
import Network from './Network';
import FullHeightContainer from '../components/FullHeightContainer';

const Home = ({ user, onError, onSuccess, onContextMenu, onUserGuideClick }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            navigate('/login');
        }
    }, [user]);

    // Determine the menu based on user roles
    let menu = [];

    if (user) {
        const adminMenu = [
            {
                icon: faLightbulb, label: 'Skill Management', link: '/skill-management', component: <SkillManagement
                    onError={onError}
                    onSuccess={onSuccess}
                />
            },
            { icon: faUserTie, label: 'HR', link: '/hr', component: <HR onError={onError} onSuccess={onSuccess} onUserGuideClick={onUserGuideClick} onContextMenu={onContextMenu} /> },
            { icon: faBriefcase, label: 'Job Fair', link: '/job-fair', component: <JobFair onError={onError} onSuccess={onSuccess} /> },
            { icon: faGraduationCap, label: 'Internships', link: '/internships', component: <Internships onError={onError} onSuccess={onSuccess} onUserGuideClick={onUserGuideClick} /> },
            { icon: faCogs, label: 'Settings', link: '/settings', component: <Settings user={user} onError={onError} onSuccess={onSuccess} /> },
            { icon: faUsersCog, label: 'User Management', link: '/user-management', component: <UserManagement user={user} onError={onError} /> },
            // { icon: faDiagramProject, label: 'Networking', component: <Network></Network>}
        ];

        const staffMenu = [
            { icon: faLightbulb, label: 'Skill Management', link: '/skill-management' },
            { icon: faBriefcase, label: 'Job Fair', link: '/job-fair' },
            { icon: faGraduationCap, label: 'Internships', link: '/internships' },
        ];

        menu = user?.isAdmin ? adminMenu : staffMenu;
    }

    return (
        <FullHeightContainer>
            {!!menu.length && <DynamicComponentRendererWithSideMenu menu={menu} />}
        </FullHeightContainer>
    );
};

export default Home;
