import React from 'react';
import PropTypes from 'prop-types';
import ActionButton from './ActionButton';
import styles from './ActionButtonsContainer.module.css';

const ActionButtonsContainer = ({ buttons = [], isFlex = true, isSmall = false }) => {
  return (
    <div className={isFlex ? styles.flexContainer : styles.blockContainer}>
      {buttons.map((button, index) => (
        <div key={index} className={styles.buttonWrapper}>
          <ActionButton icon={button.icon} label={button.label} onClick={button.onClick} isSmall={isSmall} />
        </div>
      ))}
    </div>
  );
};

export default ActionButtonsContainer;
