import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './IconButton.module.css'; // Import CSS Module for button styles

const IconButton = ({ icon, label, onClick }) => {
    return (
        <button className={styles.button} onClick={onClick}>
            <FontAwesomeIcon icon={icon} className={styles.icon} />
            <span className={styles.label}>{label}</span>
        </button>
    );
};

export default IconButton;
