import React, { useRef, useEffect, useState } from 'react';
import styles from './ContextMenu.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ContextMenu = ({ buttons, onClose, position }) => {
    const contextMenuRef = useRef(null);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const [isVisible, setIsVisible] = useState(false);

    // Close the menu when clicking outside of it
    const handleClickOutside = (event) => {
        if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);

    useEffect(() => {
        if (contextMenuRef.current) {
            const menuWidth = contextMenuRef.current.offsetWidth;
            const menuHeight = contextMenuRef.current.offsetHeight;
            const { innerWidth, innerHeight } = window;

            let { top, left } = position;

            // Adjust left position if the menu overflows the right boundary
            if (left + menuWidth > innerWidth) {
                left = innerWidth - menuWidth - 10; // 10px padding
            }

            // Adjust top position if the menu overflows the bottom boundary
            if (top + menuHeight > innerHeight) {
                top = innerHeight - menuHeight - 10; // 10px padding
            }

            // Update the position of the context menu
            setMenuPosition({ top, left });
            setIsVisible(true);
        }
    }, [position, buttons]);

    return buttons ? (
        <div
            className={styles.contextMenuContainer}
            ref={contextMenuRef}
            style={{ 
                position: 'fixed', 
                top: menuPosition.top, 
                left: menuPosition.left, 
                visibility: isVisible ? 'visible' : 'hidden',
            }}
        >
            <div className={styles.contextMenu}>
                {buttons.map((button, index) => (
                    <div
                        key={index}
                        className={styles.menuItem}
                        onClick={() => {
                            if (button.onClick) {
                                button.onClick();
                            }
                            onClose(); // Close menu after action
                        }}
                    >
                        {button.icon && <FontAwesomeIcon icon={button.icon} className={styles.icon}/>}
                        <span className={styles.label}>{button.label}</span>
                    </div>
                ))}
            </div>
        </div>
    ) : null;
};

export default ContextMenu;
