import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEllipsis, faChartSimple, faFilePdf, faFileWord, faSyncAlt, faTrash, faArrowsLeftRight, faUserPlus, faRotate, faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import styles from './Organization.module.css'; // Import the CSS module
import UserProfile from './UserProfile'; // Import UserProfile component
import ActionButtonsContainer from './ActionButtonsContainer';
import FlexContainer from './FlexContainer';
import SlidingContainer from '../components/SlidingContainer';
import EmployeeForm from './EmployeeForm';
import Checkbox from '../components/Checkbox';
import Modal from './Modal';
import _fetch from '../utils/_fetch';

const Organization = ({ data, onContextMenu, onEmployeeClick, onError, onTeamsChange, onCandidatesChange }) => {

    const [uncheckedTeams, setUncheckedTeams] = useState([]);

    const [selectedTeam, setSelectedTeam] = useState(null);
    const [isAddEmployeeModalOpen, setIsAddEmployeeModalOpen] = useState(null);

    const employeeButtons = [
        { icon: faChartSimple, label: "Radar Chart", onClick: () => { /* Handle radar chart action */ } },
        { icon: faFilePdf, label: "Download PDF", onClick: () => { /* Handle PDF download action */ } },
        { icon: faFileWord, label: "Download Word", onClick: () => { /* Handle Word download action */ } },
        { icon: faSyncAlt, label: "Request Skill Update", onClick: () => { /* Handle skill update request action */ } },
        { icon: faArrowsLeftRight, label: "Compare", onClick: () => { /* Handle compare action */ } },
        { icon: faTrash, label: "Delete", onClick: () => { /* Handle delete action */ } }
    ];

    const [teamTree, setTeamTree] = useState([]);
    const [expanded, setExpanded] = useState({
        teams: new Set(),
        leaders: new Set(),
        employees: new Set(),
        subUnits: new Set(),
        candidates: new Set(), // Add candidates to expanded state
    });

    const buildTeamTree = (teams) => {
        const teamMap = new Map();
        const tree = [];

        teams.forEach(team => {
            teamMap.set(team.id, { ...team, children: [], leaders: [], employees: [] });
        });

        teams.forEach(team => {
            if (team.parentId === null) {
                tree.push(teamMap.get(team.id));
            } else {
                const parent = teamMap.get(team.parentId);
                if (parent) {
                    parent.children.push(teamMap.get(team.id));
                }
            }
        });

        return tree;
    };

    const organizeMembers = (members, teams) => {
        const teamMap = new Map();
        teams.forEach(team => {
            teamMap.set(team.id, team);
        });

        members.forEach(member => {
            const team = teamMap.get(member.teamId);
            if (team) {
                const employee = data.employees.find(emp => emp.id === member.employeeId);
                if (employee) {
                    if (member.isLeader) {
                        team.leaders.push(employee);
                    } else {
                        team.employees.push(employee);
                    }
                }
            }
        });
    };

    const toggleSection = (section, id) => {
        setExpanded(prev => ({
            ...prev,
            [section]: new Set(prev[section].has(id) ? Array.from(prev[section]).filter(x => x !== id) : [...prev[section], id])
        }));
    };

    // Helper function to find all sub-teams recursively
    const findSubTeams = (teamId, teams) => {
        const subTeams = [];
        const findSubTeamsRecursively = (parentId) => {
            teams.forEach(team => {
                if (team.parentId === parentId) {
                    subTeams.push(team.id);
                    findSubTeamsRecursively(team.id);  // Recurse to find deeper sub-teams
                }
            });
        };
        findSubTeamsRecursively(teamId);
        return subTeams;
    };

    // Helper function to find all parent teams recursively
    const findParentTeams = (teamId, teams) => {
        const parentTeams = [];
        const findParentTeamsRecursively = (currentId) => {
            const team = teams.find(team => team.id === currentId);
            if (team && team.parentId !== null) {
                parentTeams.push(team.parentId);
                findParentTeamsRecursively(team.parentId);  // Recurse to find deeper parents
            }
        };
        findParentTeamsRecursively(teamId);
        return parentTeams;
    };

    // Handle checkbox change for a team
    const handleCheckboxChange = (teamId, isChecked) => {
        const subTeams = findSubTeams(teamId, data.teams); // Get all sub-teams
        const parentTeams = findParentTeams(teamId, data.teams); // Get all parent teams

        if (!isChecked) {
            // Uncheck the team and all its sub-teams
            setUncheckedTeams(prev => [...new Set([...prev, teamId, ...subTeams])]); // Add teamId and its sub-teams
        } else {
            // Check the team and all its sub-teams
            setUncheckedTeams(prev => prev.filter(id => id !== teamId && !subTeams.includes(id))); // Remove teamId and its sub-teams
            // Ensure parent teams are checked
            setUncheckedTeams(prev => prev.filter(id => !parentTeams.includes(id))); // Remove parent teams from uncheckedTeams
        }
    };

    const renderTeams = (teams, members) => {
        return teams.map(team => {
            const teamMembers = {
                leaders: [],
                employees: []
            };

            members.forEach(member => {
                if (member.teamId === team.id) {
                    const employee = data.employees.find(emp => emp.id === member.employeeId);
                    if (employee) {
                        if (member.isLeader) {
                            teamMembers.leaders.push(employee);
                        } else {
                            teamMembers.employees.push(employee);
                        }
                    }
                }
            });

            return (
                <div key={team.id} className={styles.folder}>
                    <div
                        className={styles.folderHeader}
                        onClick={() => toggleSection('teams', team.id)}
                    >
                        <FlexContainer
                            align='center'
                            justify='space-between'
                            style={{ width: '100%' }}
                        >
                            <FlexContainer
                                align='center'
                            >
                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    className={`${styles.chevron} ${expanded.teams.has(team.id) ? styles.rotate : ''}`}
                                />
                                {team.name}
                            </FlexContainer>
                            <Checkbox
                                checked={!uncheckedTeams.includes(team.id)}  // Checkbox is checked if team is NOT in uncheckedTeams
                                onChange={(newCheckedValue) => handleCheckboxChange(team.id, newCheckedValue)}
                            />
                        </FlexContainer>
                    </div>

                    {expanded.teams.has(team.id) && (
                        <div className={styles.folderContent}>
                            <div className={styles.subFolder}>
                                <div
                                    className={styles.subFolderHeader}
                                    onClick={() => toggleSection('leaders', team.id)}
                                >
                                    <FlexContainer
                                        align='center'
                                        justify='space-between'
                                        style={{ width: '100%' }}
                                    >
                                        <div>
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={`${styles.chevron} ${expanded.leaders.has(team.id) ? styles.rotate : ''}`}
                                            />
                                            Leaders
                                        </div>
                                        <div className={styles.actionButtonsContainer}>
                                            <ActionButtonsContainer
                                                buttons={[
                                                    {
                                                        icon: faUserPlus, label: "Add leader", onClick: (event) => {
                                                            event.stopPropagation();
                                                            setSelectedTeam(team.id);
                                                            setIsAddEmployeeModalOpen(true);
                                                        }
                                                    }
                                                ]}
                                                isSmall={true}
                                            ></ActionButtonsContainer>
                                        </div>
                                    </FlexContainer>
                                </div>
                                {expanded.leaders.has(team.id) && (
                                    <div className={styles.subFolderContent}>
                                        {teamMembers.leaders.length > 0 ? (
                                            teamMembers.leaders.map(leader => (
                                                <div key={leader.id} className={styles.employeeCard} onClick={() => onEmployeeClick(leader)}>
                                                    <UserProfile user={leader} />
                                                </div>
                                            ))
                                        ) : (
                                            <p>No leaders available.</p>
                                        )}
                                    </div>
                                )}
                            </div>

                            <div className={styles.subFolder}>
                                <div
                                    className={styles.subFolderHeader}
                                    onClick={() => toggleSection('employees', team.id)}
                                >
                                    <FlexContainer
                                        align='center'
                                        justify='space-between'
                                        style={{ width: '100%' }}
                                    >
                                        <div>
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={`${styles.chevron} ${expanded.employees.has(team.id) ? styles.rotate : ''}`}
                                            />
                                            Employees
                                        </div>
                                        <div className={styles.actionButtonsContainer}>
                                            <ActionButtonsContainer
                                                buttons={[
                                                    {
                                                        icon: faUserPlus,
                                                        label: "Add Employee",
                                                        onClick: (event) => {
                                                            event.stopPropagation();
                                                        }
                                                    },
                                                    // {
                                                    //     icon: faRotate,
                                                    //     label: "Request Skill Updates",
                                                    //     onClick: (event) => {
                                                    //         event.stopPropagation();
                                                    //     }
                                                    // },
                                                    // {
                                                    //     icon: faArrowsLeftRight,
                                                    //     label: "Compare All",
                                                    //     onClick: (event) => {
                                                    //         event.stopPropagation();
                                                    //     }
                                                    // }
                                                ]}
                                                isSmall={true}
                                            ></ActionButtonsContainer>
                                        </div>
                                    </FlexContainer>
                                </div>
                                {expanded.employees.has(team.id) && (
                                    <div className={styles.subFolderContent}>
                                        {teamMembers.employees.length > 0 ? (
                                            teamMembers.employees.map(emp => (
                                                <div key={emp.id} className={styles.employeeCard} onClick={() => onEmployeeClick(emp)}>
                                                    <UserProfile key={emp.id} user={emp} />
                                                </div>
                                            ))
                                        ) : (
                                            <p>No employees available.</p>
                                        )}
                                    </div>
                                )}
                            </div>

                            <div className={styles.subFolder}>
                                <div
                                    className={styles.subFolderHeader}
                                    onClick={() => toggleSection('subUnits', team.id)}
                                >
                                    <FlexContainer
                                        align='center'
                                        justify='space-between'
                                        style={{ width: '100%' }}
                                    >
                                        <div>
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={`${styles.chevron} ${expanded.subUnits.has(team.id) ? styles.rotate : ''}`}
                                            />
                                            Sub-Units
                                        </div>
                                        <div className={styles.actionButtonsContainer}>
                                            <ActionButtonsContainer
                                                buttons={[
                                                    {
                                                        icon: faFolderPlus,
                                                        label: "Add Team",
                                                        onClick: (event) => {
                                                            event.stopPropagation();
                                                        }
                                                    }
                                                ]}
                                                isSmall={true}
                                            ></ActionButtonsContainer>
                                        </div>
                                    </FlexContainer>
                                </div>
                                {expanded.subUnits.has(team.id) && (
                                    <div className={styles.subFolderContent}>
                                        {renderTeams(team.children, members)}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            );
        });
    };



    // Candidate states
    const candidateStates = ['new', 'active 1', 'active 2', 'sleep 1', 'sleep 2', 'cancelled'];

    const [checkedStates, setCheckedStates] = useState(candidateStates); // Track checked candidate states with an array
    const [allChecked, setAllChecked] = useState(true); // Track if the top-level checkbox is checked

    // Handle top-level checkbox toggle
    const handleAllCandidatesToggle = (isChecked) => {
        setAllChecked(isChecked);

        // If the top-level checkbox is checked, check all states, otherwise uncheck all
        if (isChecked) {
            setCheckedStates([...candidateStates]); // Check all states
        } else {
            setCheckedStates([]); // Uncheck all states
        }
    };

    // Handle individual state checkbox toggle
    const handleStateToggle = (state, isChecked) => {
        setCheckedStates((prevCheckedStates) => {
            let newCheckedStates;

            if (isChecked) {
                // Add the state if checked
                newCheckedStates = [...prevCheckedStates, state];
            } else {
                // Remove the state if unchecked
                newCheckedStates = prevCheckedStates.filter(s => s !== state);
            }

            // Update top-level checkbox based on whether all states are checked
            setAllChecked(newCheckedStates.length === candidateStates.length);

            return newCheckedStates;
        });
    };

    const renderCandidates = (candidates) => {
        const candidateStates = ['new', 'active 1', 'active 2', 'sleep 1', 'sleep 2', 'cancelled'];

        const groupedCandidates = candidateStates.reduce((acc, state) => {
            acc[state] = candidates.filter(candidate => candidate.state === state);
            return acc;
        }, {});

        return (
            <div className={styles.folder}>
                <div className={styles.folderHeader} onClick={() => toggleSection('candidates', 'all')}>
                    <FlexContainer
                        align='center'
                        justify='space-between'
                        style={{ width: '100%' }}
                    >
                        <FlexContainer
                            align='center'
                        >
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className={`${styles.chevron} ${expanded.candidates.has('all') ? styles.rotate : ''}`}
                            />
                            Candidates
                        </FlexContainer>
                        <Checkbox
                            checked={allChecked}
                            onChange={handleAllCandidatesToggle} // Toggle all states when top-level checkbox is changed
                            title="If this option is not selected, all states will be unchecked."
                        ></Checkbox>
                    </FlexContainer>
                </div>
                {expanded.candidates.has('all') && (
                    <div className={styles.folderContent}>
                        {candidateStates.map(state => (
                            <div key={state} className={styles.subFolder}>
                                <div className={styles.subFolderHeader} onClick={() => toggleSection('candidates', state)}>
                                    <FlexContainer
                                        align='center'
                                        justify='space-between'
                                        style={{ width: '100%' }}
                                    >
                                        <FlexContainer
                                            align='center'
                                        >
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={`${styles.chevron} ${expanded.candidates.has(state) ? styles.rotate : ''}`}
                                            />
                                            {state.charAt(0).toUpperCase() + state.slice(1)} {/* Capitalize the state name */}
                                        </FlexContainer>

                                        <Checkbox
                                            checked={checkedStates.includes(state)} // Checkbox checked if state is in checkedStates array
                                            onChange={(isChecked) => handleStateToggle(state, isChecked)} // Handle individual state toggle
                                            title={`Toggle ${state}`}
                                        ></Checkbox>
                                    </FlexContainer>
                                </div>
                                {expanded.candidates.has(state) && (
                                    <div className={styles.subFolderContent}>
                                        {groupedCandidates[state].length > 0 ? (
                                            groupedCandidates[state].map(candidate => (
                                                <div key={candidate.id} className={styles.employeeCard}>
                                                    <UserProfile
                                                        user={{
                                                            firstName: candidate.firstName,
                                                            lastName: candidate.lastName,
                                                            description: candidate.description,
                                                        }}
                                                    />
                                                </div>
                                            ))
                                        ) : (
                                            <p>No candidates available.</p>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    useEffect(() => {
        if (data) {
            const tree = buildTeamTree(data.teams);
            organizeMembers(data.team_members, tree);
            setTeamTree(tree);
        }
    }, [data]);

    // Propagate uncheckedTeams up to parent whenever it changes
    useEffect(() => {
        onTeamsChange(uncheckedTeams); // Call the callback with the updated list of unchecked teams
    }, [uncheckedTeams, onTeamsChange]);

    // Propagate checkedStates up to parent whenever it changes
    useEffect(() => {
        onCandidatesChange(checkedStates); // Call the callback with the updated list of checked candidate states
    }, [checkedStates, onCandidatesChange]);

    return (
        <>
            <div className={styles.teamTree}>
                {renderTeams(teamTree, data?.team_members)}
            </div>
            <div className={styles.candidates}>
                {data?.candidates && renderCandidates(data?.candidates)}
            </div>



            <Modal
                isOpen={isAddEmployeeModalOpen}
                onClose={() => { setIsAddEmployeeModalOpen(false); }}
            >
                <h2>Add New Employee</h2>
                <EmployeeForm
                    onSubmit={async () => {
                        try {

                        } catch (error) {
                            onError(error.message);
                        }
                    }}
                    onError={onError}
                ></EmployeeForm>
            </Modal>

        </>
    );
};

export default Organization;
