import React, { useEffect, useState } from 'react';
import { faFilePdf, faEdit, faTrash, faFileWord, faCrosshairs, faEllipsis, faExpand, faCompress, faChartSimple, faCloudUpload, faRedo, faArrowsAltH, faChevronRight, faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FlexContainer from "../components/FlexContainer";
import UserProfile from "../components/UserProfile";
import ActionButtonsContainer from '../components/ActionButtonsContainer';
import PaddingContainer from '../components/PaddingContainer';
import DynamicComponentRenderer from '../components/DynamicComponentRenderer';
import styles from './EmployeeProfile.module.css';
import WorkInProgress from './WorkInProgress';
import _fetch from '../utils/_fetch';
import CenteredMessage from '../components/CenteredMessage';
import SkillCheckForm from '../components/SkillCheckForm';

const EmployeeProfile = ({
    employee,
    onContextMenu,
    onError,
    onSuccess,
    onEditEmployee

}) => {
    const [skillUpdateRequests, setSkillUpdateRequests] = useState([]);
    const [openRequestIds, setOpenRequestIds] = useState([]); // Array to track which cards are open

    useEffect(() => {
        const fetchSkillUpdateRequests = async () => {
            setSkillUpdateRequests([]);
            try {
                const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/skill-update-request?employeeId=${employee.id}`, 'GET');
                setSkillUpdateRequests(responseData.requests);
            } catch (error) {
                // onError(error.message);
            }
        };
        fetchSkillUpdateRequests();
    }, [employee]);

    const toggleCardOpen = (id) => {
        setOpenRequestIds(prevIds =>
            prevIds.includes(id) ? prevIds.filter(item => item !== id) : [...prevIds, id]
        );
    };

    // Format date based on locale
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const userLocale = navigator.language || 'en-US'; // Use browser's locale or default to 'en-US'
        return new Intl.DateTimeFormat(userLocale, { dateStyle: 'medium' }).format(date);
    };

    const handleEdit = () => {
        console.log('Edit button clicked');
    };

    const handleDelete = () => {
        console.log('Delete button clicked');
    };

    const handleSendSkillUpdateRequest = async () => {
        try {
            const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/skill-update-request`, 'POST', {
                employeeId: employee.id
            });
            console.log(responseData);
            onSuccess(responseData.message);
        } catch (error) {
            onError(error.message);
            console.log(error);
        }
    };

    const handleDownloadPDF = (employeeId) => {
        // Construct the URL for the PDF download
        const url = `${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/export/pdf?employeeId=${employeeId}`;

        // Open the URL in a new window or tab
        window.open(url, '_blank');
    };

    const handleDownloadWord = (employeeId) => {
        // Construct the URL for the Word download
        const url = `${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/export/word?employeeId=${employeeId}`;

        // Open the URL in a new window or tab
        window.open(url, '_blank');
    };


    const contextButtons = [
        { icon: faExpand, label: "Expand", onClick: () => { console.log('Expand clicked'); } },
        { icon: faCompress, label: "Collapse", onClick: () => { console.log('Collapse clicked'); } },
        { icon: faChartSimple, label: "Radar Chart", onClick: () => { console.log('Radar Chart clicked'); } },
        { icon: faFilePdf, label: "Download PDF", onClick: handleDownloadPDF },
        { icon: faFileWord, label: "Download Word", onClick: handleDownloadWord },
        { icon: faCloudUpload, label: "Upload to PQForce", onClick: () => { console.log('Upload clicked'); } },
        { icon: faRedo, label: "Resend Request", onClick: () => { console.log('Resend clicked'); } },
        { icon: faArrowsAltH, label: "Compare", onClick: () => { console.log('Compare clicked'); } },
        { icon: faTrash, label: "Delete", onClick: handleDelete },
    ];


    const fetchSkillUpdateRequest = async (token, fetchUser = 0) => {
        try {
            // Fetch the data from the backend
            const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/skill-update-request/${token}/${fetchUser}`, 'GET');
    
            // Destructure the response data to get skillUpdateRequest, user, and skills
            const { skillUpdateRequest, user, skills } = responseData;
    
            // Find the corresponding skill update request by id in the local array and update it with the fetched data
            const updatedSkillUpdateRequests = skillUpdateRequests.map((request) => {
                if (request.id === skillUpdateRequest.id) {
                    // Merge the new data (skillUpdateRequest, user, skills) into the current request
                    return {
                        ...request,
                        ...skillUpdateRequest,
                        user,   // Add the user data (or null if fetchUser = 0)
                        skills  // Add the skills array
                    };
                }
                return request;
            });
    
            // Update the state or wherever you store the skill update requests
            setSkillUpdateRequests(updatedSkillUpdateRequests);
    
        } catch (error) {
            onError(error.message); // Handle any errors
        }
    };


    return (
        <PaddingContainer>
            <FlexContainer align='center' justify='space-between'>
                <UserProfile user={employee} profilePictureWidth={200} />
                <ActionButtonsContainer
                    buttons={[
                        { icon: faFileWord, label: "Download Word", onClick: () => { handleDownloadWord(employee.id); } },
                        { icon: faFilePdf, label: "Download PDF", onClick: () => { handleDownloadPDF(employee.id); } },
                        { icon: faEdit, label: "Edit", onClick: onEditEmployee },
                        { icon: faTrash, label: "Delete", onClick: handleDelete },
                        { icon: faRotate, label: "Send Skill Update Request", onClick: handleSendSkillUpdateRequest },
                    ]}
                />
            </FlexContainer>

            <DynamicComponentRenderer
                components={[
                    {
                        label: "Hard Skills", component: (
                            <>
                                {skillUpdateRequests.map((request) => (
                                    <React.Fragment key={request.id}>
                                        <div
                                            className={`${styles.skillUpdateRequestCard} ${openRequestIds.includes(request.id) ? styles.open : ''}`}
                                            onClick={() => {
                                                toggleCardOpen(request.id);
                                                fetchSkillUpdateRequest(request.token);
                                            }}
                                            onContextMenu={(event) => {
                                                event.stopPropagation();
                                                onContextMenu(contextButtons, event);
                                            }}
                                        >
                                            <div className={styles.chevron}>
                                                <FontAwesomeIcon
                                                    icon={faChevronRight}
                                                    className={`${styles.chevronIcon} ${openRequestIds.includes(request.id) ? styles.chevronOpen : ''}`}
                                                />
                                            </div>
                                            <div className={styles.requestType}>
                                                <small>Type</small>
                                                <span>{request.type}</span>
                                            </div>
                                            <div className={styles.requestedBy}>
                                                <small>Requested by</small>
                                                <span>
                                                    {request.requestedByFirstName && request.requestedByLastName
                                                        ? `${request.requestedByFirstName} ${request.requestedByLastName}`
                                                        : "unknown"}
                                                </span>

                                            </div>
                                            <div className={styles.requestedOn}>
                                                <small>Requested on</small>
                                                <span>{formatDate(request.requestDate)}</span>
                                            </div>
                                            <div className={styles.state}>
                                                <small>State</small>
                                                <span>{request.state}</span>
                                            </div>
                                            {/* <div className={styles.contextMenu}>
                                                <div className={styles.actionButtonContainer}>
                                                    <ActionButtonsContainer
                                                        buttons={[
                                                            {
                                                                icon: faEllipsis, onClick: (event) => {
                                                                    event.stopPropagation();
                                                                    onContextMenu(contextButtons, event);
                                                                }
                                                            }
                                                        ]}
                                                    />
                                                </div>
                                            </div> */}
                                        </div>
                                        {openRequestIds.includes(request.id) && (
                                            <div className={styles.additionalContent}>

                                                {!!request.skills?.length && request.skills && <SkillCheckForm
                                                    preloadedSkills={request.skills}
                                                    isInEditingMode={false}
                                                    showPreloadedSkillsOnly={true}
                                                ></SkillCheckForm>}

                                                {!request.skills?.length && <CenteredMessage message="No skills found for this skill update request."></CenteredMessage>}
                                            </div>
                                        )}
                                    </React.Fragment>
                                ))}
                                {!skillUpdateRequests.length && <CenteredMessage message={"No Skill Update Requests found."}></CenteredMessage>}
                            </>
                        )
                    },
                    // { label: "Soft Skills", component: <WorkInProgress></WorkInProgress> }
                ]}
            />
        </PaddingContainer>
    );
};

export default EmployeeProfile;
