import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown, faLightbulb, faClose } from "@fortawesome/free-solid-svg-icons";
import AboutCompany from "../components/AboutCompany";
import Spinner from "../components/Spinner";
import _fetch from "../utils/_fetch";
import { Link, useParams } from "react-router-dom";
import MaxWidth from "../components/MaxWidth";
import FullLayout from "../components/FullLayout";

// Import CSS module
import styles from './ContactRequest.module.css';
import Button from "../components/Button";

const ContactRequest = ({ onError, onSuccess }) => {
    const { token } = useParams();  // Get the token from the URL
    const [isLoading, setIsLoading] = useState(true);
    const [companyId, setCompanyId] = useState(null);
    const [companyData, setCompanyData] = useState(null);
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
    const [showTermsDialog, setShowTermsDialog] = useState(false);

    useEffect(() => {
        const verifyToken = async () => {
            try {
                const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER}/api/contact-request/verify/${token}`, 'GET');
                setCompanyId(responseData.companyId);

                // Fetch additional company details if needed
                const companyResponse = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER}/api/companies/${responseData.companyId}`, 'GET');
                setCompanyData(companyResponse);
                onSuccess("Contact request verified successfully.");
            } catch (error) {
                onError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        verifyToken();
    }, [token, onError, onSuccess]);

    const handleAccept = async () => {
        if (hasAcceptedTerms) {
            try {
                // Fetch user data
                const userResponse = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER}/api/users/me`, 'GET');
                const userData = userResponse;

                // Send acceptance and user data to the company
                await _fetch(`${process.env.REACT_APP_BACKEND_SERVER}/api/contact-request/accept/${token}`, 'POST', {
                    body: JSON.stringify({
                        userData: {
                            firstName: userData.firstName,
                            lastName: userData.lastName,
                            email: userData.email,
                            profilePicture: userData.profilePicture
                        }
                    })
                });

                onSuccess("You have accepted the contact request.");
            } catch (error) {
                onError(error.message);
            }
        } else {
            setShowTermsDialog(true);
        }
    };

    const handleDecline = async () => {
        try {
            await _fetch(`${process.env.REACT_APP_BACKEND_SERVER}/api/contact-request/decline/${token}`, 'POST');
            onSuccess("You have declined the contact request.");
        } catch (error) {
            onError(error.message);
        }
    };

    const handleTermsAcceptance = () => {
        setHasAcceptedTerms(true);
        setShowTermsDialog(false);
        handleAccept();
    };

    return (
        <>
            {isLoading ? (
                <Spinner />
            ) : (
                <FullLayout
                    buttons={[
                        {icon: faLightbulb, label: "My Skills", onClick: () => {}},
                        {icon: faClose, label: "Delete Profile", onClick: () => {}}
                    ]}
                >
                    <MaxWidth>
                        <h1>Congratulations!</h1>
                        <p>
                            Exciting news! A company has recognized your potential and is interested in connecting with you for a promising internship opportunity.
                        </p>
                        <p>
                            Take a moment to explore the company’s profile and review the details of the internship position. Once you’ve reviewed the information, you can choose to either accept or decline their invitation to connect.
                        </p>

                        {companyId && <AboutCompany companyId={companyId} companyData={companyData} />}

                        <div className={styles.disclaimer}>
                            <p>
                                By accepting this contact request, you agree to share your personal information, including your name, email, and profile picture, with the company. You also agree to our <Link to="/terms-and-conditions">Terms and Conditions</Link> and <Link to="/privacy-policy">Privacy Policy</Link>.
                            </p>
                        </div>

                        <div className={styles.actionsContainer}>
                            <button className={styles.acceptButton} onClick={handleAccept}>
                                <FontAwesomeIcon icon={faThumbsUp} className={styles.icon} />
                                <div>Accept</div>
                            </button>
                            <button className={styles.declineButton} onClick={handleDecline}>
                                <FontAwesomeIcon icon={faThumbsDown} className={styles.icon} />
                                <div>Decline</div>
                            </button>
                        </div>

                        <div className={styles.reportButton}>Report Company</div>
                    </MaxWidth>
                </FullLayout>
            )}
        </>
    );
};

export default ContactRequest;
