// Flex.js
import React from 'react';
import PropTypes from 'prop-types';
import styles from './FlexContainer.module.css';

const FlexContainer = ({ children, direction = 'row', justify = 'flex-start', align = 'stretch', wrap = 'nowrap', gap = 0, style }) => {
  const flexContainerClasses = [
    styles.flexContainer,
    direction && styles[direction],
    justify && styles[`justify-${justify}`],
    align && styles[`align-${align}`],
    wrap && styles[wrap],
  ].join(' ');

  return (
    <div className={flexContainerClasses} style={{ gap, ...style }}>
      {children}
    </div>
  );
};

FlexContainer.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.oneOf(['row', 'column']),
  justify: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around']),
  align: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'baseline', 'stretch']),
  wrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
  gap: PropTypes.number,
};

export default FlexContainer;
