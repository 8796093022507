import { useState } from "react";
import Button from "../components/Button";
import InputField from "../components/InputField"
import Modal from "../components/Modal"
import _fetch from "../utils/_fetch";

const VerifyPassword = ({ isOpen, onClose, onPasswordVerified }) => {

    const [password, setPassword] = useState(null);

    const handlePasswordVerification = async (event) => {
        event.preventDefault();
        try {
            const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/auth/password/verify`, 'POST',{
                password
            });
            onPasswordVerified(responseData);
        } catch (error) {
            onClose();
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <h2>Enter your Password</h2>
            <form onSubmit={handlePasswordVerification}>
                <InputField
                    value={password}
                    onChange={(event) => { setPassword(event.target.value); }}
                    label="Password"
                    required={true}
                ></InputField>
                <Button type="submit">Next</Button>
            </form>
        </Modal>
    );
};

export default VerifyPassword;