import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./AboutCompany.module.css";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import SocialIcons from "./SocialIcons";
import ActionButtonsContainer from "./ActionButtonsContainer";

const AboutCompany = () => {

    const [company, setCompany] = useState(null);

    return (
        <>
            {company && <>
                <h2>{company.name}</h2>

                <div className={styles.logo}>
                    <FontAwesomeIcon icon={faCamera} className={styles.camera}></FontAwesomeIcon>
                    <p>Upload your company logo.</p>
                </div>
                <ActionButtonsContainer
                    buttons={[
                        { icon: faCamera, label: "Upload Company Logo", onclick: () => { alert('Upload company logo Clicked'); } }
                    ]}
                ></ActionButtonsContainer>
                <h3>About Us</h3>
                <p>{company.aboutUs}</p>

                <h3>Locations</h3>
                {
                    company.locations.map((location, index) => {
                        return (
                            <div key={index}>
                                {location.address}
                            </div>
                        );
                    })
                }

                <h3>Contact</h3>
                {
                    company.contacts.map((contact, index) => {
                        return (
                            <div key={index}>
                            </div>
                        );
                    })
                }

                <h3>Socials</h3>
                <SocialIcons links={company.links ?? []}></SocialIcons>
            </>}
        </>
    );
};

export default AboutCompany;