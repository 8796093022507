import { useState } from 'react';
import InputField from './InputField';
import Button from './Button';
import _fetch from '../utils/_fetch';
import Select from './Select';
import _maturityOptions from '../utils/_maturityOptions';
import ProfilePicture from './ProfilePicture';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud, faCloudArrowUp, faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import compressImage from './utils/CompressImage'; // Ensure this is imported correctly
import fileToBase64 from './utils/FileToBase64'; // Ensure this is imported correctly
import removeDataUrlPrefix from './utils/RemoveDataUrlPrefix';
import CenteredContainer from './CenteredContainer';
import FlexContainer from './FlexContainer';
import ActionButtonsContainer from './ActionButtonsContainer';

const EmployeeForm = ({ employee, onSubmit, onError, sendSkillCheck = false }) => {
    const [formData, setFormData] = useState({
        id: employee?.id || '',
        firstName: employee?.firstName || '',
        lastName: employee?.lastName || '',
        email: employee?.email || '',
        maturity: employee?.maturity || 'professional',
        description: employee?.description || '',
        profilePicture: employee?.profilePicture || ''
    });

    const maturityOptions = _maturityOptions(formData.maturity || 'professional');

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    const handlePictureUpload = async (file) => {
        try {
            // Compress image
            const compressedFile = await compressImage(file);

            // Convert image to base64
            const base64Picture = await fileToBase64(compressedFile);

            // Remove the data URL prefix (e.g., data:image/png;base64,)
            const base64Data = removeDataUrlPrefix(base64Picture);

            if (formData?.id) {
                // Upload picture
                await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/employee/${formData.id}/profile-picture`, 'POST', {
                    base64Picture: base64Data
                });
            }

            // Update form data
            setFormData((prevData) => ({
                ...prevData,
                profilePicture: base64Picture
            }));
        } catch (error) {
            onError(error.message);
        }
    };


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            handlePictureUpload(file);
        }
    };

    const handlePictureDelete = async () => {
        try {
            if (formData?.id) {
                await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/employee/${formData.id}/profile-picture`, 'DELETE');
            }
            setFormData((prevData) => ({
                ...prevData,
                profilePicture: ''
            }));
        } catch (error) {
            onError(error.message);
        }
    };

    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    return (
        <>
            <CenteredContainer>
                <ProfilePicture
                    user={formData}
                    width={300}
                />
            </CenteredContainer>
            {!formData.profilePicture && (
                <div>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        id="fileInput"
                    />
                    <ActionButtonsContainer
                        buttons={[
                            {
                                icon: faCloudArrowUp,
                                title: 'Upload Picture',
                                onClick: triggerFileInput
                            }
                        ]}
                    ></ActionButtonsContainer>
                </div>
            )}
            {formData.profilePicture && (
                <ActionButtonsContainer
                    buttons={[
                        {
                            icon: faTrash,
                            title: 'Delete Picture',
                            onClick: handlePictureDelete
                        }
                    ]}
                ></ActionButtonsContainer>
            )}
            <form onSubmit={handleSubmit}>
                <InputField
                    id="firstName"
                    type="text"
                    value={formData.firstName}
                    onChange={handleChange}
                    label="First Name"
                    required={true}
                />
                <InputField
                    id="lastName"
                    type="text"
                    value={formData.lastName}
                    onChange={handleChange}
                    label="Last Name"
                    required={true}
                />
                <InputField
                    id="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    label="Email"
                    required={true}
                />
                <Select
                    label="Maturity"
                    options={maturityOptions}
                    value={formData.maturity}
                    onOptionSelect={(maturity) => {
                        setFormData((prevData) => ({
                            ...prevData,
                            maturity
                        }));
                    }}
                    isRequired={true}
                />
                <InputField
                    id="description"
                    type="text"
                    value={formData.description}
                    onChange={handleChange}
                    label="Description"
                />

                {sendSkillCheck && <FlexContainer
                    align='center'
                    gap={10}
                >
                    <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                    <p>This page will be redirected to the skill-check.</p>
                </FlexContainer>}

                <Button type="submit">Save</Button>
            </form>
        </>
    );
};

export default EmployeeForm;
