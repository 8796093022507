// NotFoundPage.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import styles from './NotFound.module.css'; // Import the CSS module

const NotFound = () => {
    return (
        <div className={styles.container}>
            {/* <FontAwesomeIcon icon={faExclamationTriangle} size="3x" className={styles.icon} /> */}
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for does not exist. Please check the URL or go back to the homepage.</p>
        </div>
    );
};

export default NotFound;
