import React, { useState, useRef } from 'react';
import styles from './Verify2FACode.module.css'; // Adjust the import path as necessary
import _fetch from '../utils/_fetch'; // Adjust the import path as necessary

const Verify2FACode = ({ onError, onSuccess, on2FAReset, onCodeVerified, email, otp }) => {
    const [code, setCode] = useState(Array(6).fill(''));
    const inputRefs = useRef([]);

    const handleChange = (index, event) => {
        const value = event.target.value;
        const numericValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        const newCode = [...code];
        newCode[index] = numericValue;
        setCode(newCode);

        // Automatically move focus to the next field and clear that field
        if (numericValue.length === 1 && index < 5) {
            const nextInput = inputRefs.current[index + 1];
            if (nextInput) {
                nextInput.focus();
                nextInput.value = ''; // Clear the next field
                setCode(prevCode => {
                    const updatedCode = [...prevCode];
                    updatedCode[index + 1] = '';
                    return updatedCode;
                });
            }
        }

        // Verify code once all inputs are filled (only after entering the last digit)
        if (index === 5 && numericValue.length === 1) {
            verifyCode(newCode.join(''));
        }
    };

    const handleFocus = (index, event) => {
        // Clear the input field on focus
        event.target.value = '';
        setCode(prevCode => {
            const newCode = [...prevCode];
            newCode[index] = ''; // Clear the value in the state
            return newCode;
        });
    };

    const verifyCode = async (code) => {
        try {
            const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/auth/2fa/verify`, 'POST', {
                code,
                email,
                otp
            });
            onCodeVerified();
        } catch (error) {
            onError(error.message);
        }
    };

    const handleReset2FA = async() => {
        try {
            const responseData = await _fetch(`${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/auth/2fa/reset`,'GET');
            onSuccess(responseData.message);
            on2FAReset();
        } catch (error) {
            onError(error.message);
        }
    }

    return (
        <>
            <h2>Verify 2FA Code</h2>
            <p>Enter the 6-digit code shown in your app.</p>
            <div className={styles.verifyContainer}>
                <div className={styles.inputGroup}>
                    {code.map((value, index) => (
                        <input
                            key={index}
                            id={`code-input-${index}`}
                            type="text"
                            value={value}
                            onChange={(e) => handleChange(index, e)}
                            onFocus={(e) => handleFocus(index, e)}
                            maxLength="1" // Enforce single character input
                            className={styles.input}
                            pattern="[0-9]*" // Allow only numeric input on mobile
                            ref={(el) => inputRefs.current[index] = el} // Use ref to keep a reference to the input
                        />
                    ))}
                </div>
            </div>
            {on2FAReset && <p>Have you lost your device? <span className={styles.reset2faButton} onClick={handleReset2FA}>Reset 2FA</span></p>}
        </>
    );
};

export default Verify2FACode;
